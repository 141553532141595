import { MinusOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDate } from '../../../../../components/DisplayDate';
import { DisplayName } from '../../../../../components/DisplayName';
import { ExpandArrow } from '../../../../../components/ExpandArrow';
import {
  GetMitemDetailsDocument,
  MitemActionType,
  SprintMitemTable_SkaTimeStatusFragment,
} from '../../../../../generated/graphql';
import { StarIcon } from '../../../../../icons/Star';
import { dateCompare } from '../../../../../services/dateHelpers';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { mitemDefaultSortOrder } from '../../../../../services/sprintKeyActivityUtils';
import { stringSort } from '../../../../../services/stringSort';
import { mitemStatusColor } from '../../../../../styleVars';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import {
  Icons,
  InitiativeIcon,
} from '../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { Colors } from '../../../../componentLibrary/Colors';
import { TagImage } from '../../../setup/overviewPage/labels/TagImage';
import { tagsContext } from '../../common/TeamSprintProvider';
import { MitemFilterStatus } from '../../common/components/mitemFilters/StatusFilter';
import { MitemHistory } from '../../common/components/MitemHistory';
import {
  migFilterCompare,
  ownerFilterCompare,
  statusFilterCompare,
  sprintKeyActivityHasTagMatch,
} from '../../common/hooks/useMitemFilters';
import './SprintMitemTable.less';
import { CommitColumn } from './sprintMitemTable/CommitColumn';
import { StatusTag } from '../../../../../components/StatusTag';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';
import { match } from 'ts-pattern';

export type MitemRescheduleAction = {
  action: MitemActionType.PLANNED;
  date: Moment;
};

export type MitemCompleteAction = {
  action: MitemActionType.COMPLETED;
};

export type MitemDeleteAction = {
  action: MitemActionType.ARCHIVED;
};

export type MitemAction =
  | MitemRescheduleAction
  | MitemDeleteAction
  | MitemCompleteAction;

interface Props {
  timeStatuses: SprintMitemTable_SkaTimeStatusFragment[] | null;
  loading?: boolean;
  filters?: { ownerId?: string; status?: MitemFilterStatus; migId?: string };
  actionColumn?: ColumnProps<SprintMitemTable_SkaTimeStatusFragment>;
  pagination?: boolean;
  includeStatus?: boolean;
  committable?: boolean;
  mitemActions?: {
    [mitemId: string]: MitemAction | null;
  };
}

interface ColumnPropsWithExclude<T> extends ColumnProps<T> {
  excluded?: boolean;
}

const sortTeamTags = (timeStatus: SprintMitemTable_SkaTimeStatusFragment) => {
  const teamTags = timeStatus.sprintKeyActivity?.tags ?? [];

  const sortedTeamTags = [...teamTags].sort((a, b) => {
    return stringSort(a.name, b.name);
  });
  return sortedTeamTags;
};

const sortInitiativeTags = (
  features: {
    teamInitiativesEnabled: boolean;
    tenantInitiativesEnabled: boolean;
  },
  timeStatus: SprintMitemTable_SkaTimeStatusFragment
) => {
  const supportedInitiatives = match(features)
    .with({ teamInitiativesEnabled: true }, () => {
      return (
        timeStatus.sprintKeyActivity?.supportedInitiatives?.map(
          (teamInitiative) => {
            return {
              id: teamInitiative.id,
              tag: teamInitiative.tag,
              metadata: {
                archived: teamInitiative.archived,
                completedAt: teamInitiative.completed.value
                  ? teamInitiative.completed
                  : null,
              },
            };
          }
        ) ?? []
      );
    })
    .with({ tenantInitiativesEnabled: true }, () => {
      return (
        timeStatus.sprintKeyActivity?.supportsInitiatives2?.map(
          (i) => i.initiative
        ) ?? []
      );
    })
    .otherwise(() => {
      return [];
    });

  const sortedInitiativeTags = [...supportedInitiatives].sort((a, b) => {
    return stringSort(a.tag.title, b.tag.title);
  });
  return sortedInitiativeTags;
};

export const SprintMitemTable = ({
  timeStatuses,
  loading,
  filters,
  actionColumn,
  pagination = false,
  includeStatus = true,
  committable = false,
  mitemActions,
}: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const { tagsSelectedForFiltering, untaggedOnly, andOrChoice } = useContext(
    tagsContext
  ) as any;

  const filteredMitems = timeStatuses
    ?.filter((m) => ownerFilterCompare(m.owner.id, filters?.ownerId))
    .filter((m) => statusFilterCompare(m.status, filters?.status))
    .filter((m) =>
      migFilterCompare(
        m.sprintKeyActivity?.supportedMigs.map((sm) => sm.domainId.itemId) ??
          [],
        filters?.migId
      )
    );

  const [sortInfo, setSortedInfo] = useState<
    SorterResult<SprintMitemTable_SkaTimeStatusFragment>[]
  >([]);

  const handleChange = (
    sorter:
      | SorterResult<SprintMitemTable_SkaTimeStatusFragment>
      | SorterResult<SprintMitemTable_SkaTimeStatusFragment>[]
  ) => {
    setSortedInfo(Array.isArray(sorter) ? sorter : [sorter]);
  };

  useEffect(() => {
    setSortedInfo([{ columnKey: 'deadline', order: 'ascend' }]);
  }, [tagsSelectedForFiltering]);

  const sprintKeyActivityHasNoTags = (
    ts: SprintMitemTable_SkaTimeStatusFragment
  ) => {
    return (
      ts.sprintKeyActivity?.tags &&
      ts.sprintKeyActivity.tags.length === 0 &&
      ts.sprintKeyActivity?.supportedInitiatives &&
      ts.sprintKeyActivity?.supportedInitiatives.length === 0
    );
  };

  const sprintKeyActivityHasTags = (
    ts: SprintMitemTable_SkaTimeStatusFragment
  ) => {
    return (
      (ts.sprintKeyActivity?.tags && ts.sprintKeyActivity.tags.length > 0) ||
      (ts.sprintKeyActivity?.supportedInitiatives &&
        ts.sprintKeyActivity?.supportedInitiatives.length > 0)
    );
  };

  const sprintMitemColumns: ColumnPropsWithExclude<SprintMitemTable_SkaTimeStatusFragment>[] =
    [
      {
        title: t('common.sprintKeyActivity.status'),
        excluded: !includeStatus,
        width: 90,
        key: 'status',
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          return (
            t ||
            mitemDefaultSortOrder.indexOf(a.status) -
              mitemDefaultSortOrder.indexOf(b.status)
          );
        },
        sortOrder: sortInfo.find((i) => i.columnKey === 'status')?.order,
        render: (_, timeStatus) => {
          return (
            <div className="flx flx--column dimmable">
              <StatusTag status={timeStatus.status} />
            </div>
          );
        },
      },
      {
        title: t('common.title'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          return t || stringSort(a.name, b.name);
        },
        sortOrder: sortInfo.find((i) => i.columnKey === 'name')?.order,
        render: (_, timeStatus) => {
          return (
            <div className="dimmable">
              <Typography.Text>{timeStatus.name}</Typography.Text>
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.owner'),
        dataIndex: ['owner', 'id'],
        key: 'owner',
        sortOrder: sortInfo.find((i) => i.columnKey === 'owner')?.order,
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const ownerSort = stringSort(
            friendlyUsername(a.owner),
            friendlyUsername(b.owner)
          );
          const deadlineSort = dateCompare(a.deadline, b.deadline);
          const deadlineDirection = sortOrder === 'descend' ? -1 : 1;

          return t || ownerSort || deadlineSort * deadlineDirection;
        },
        render: (_, timeStatus) => {
          return (
            <div className="dimmable">
              <DisplayName user={timeStatus.owner} />
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.deadline'),
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const deadlineSort = dateCompare(a.deadline, b.deadline);
          const ownerSort = stringSort(
            friendlyUsername(a.owner),
            friendlyUsername(b.owner)
          );
          return t || deadlineSort || ownerSort;
        },
        defaultSortOrder: 'ascend',
        dataIndex: 'deadline',
        key: 'deadline',
        sortOrder: sortInfo.find((i) => i.columnKey === 'deadline')?.order,
        render: (deadline, timeStatus) => {
          const formattedDeadline = (
            <div className="dimmable">
              <DisplayDate date={deadline} />
            </div>
          );

          const handledMitem = mitemActions?.[timeStatus.id];
          if (handledMitem?.action === MitemActionType.PLANNED) {
            return (
              <div className={'flx flx--column'}>
                <Typography.Text type="secondary" className="lineThrough">
                  {formattedDeadline}
                </Typography.Text>
                <div style={{ color: mitemStatusColor.PLANNED }}>
                  <DisplayDate date={handledMitem.date} />
                </div>
              </div>
            );
          }
          return formattedDeadline;
        },
      },
      {
        title: <StarIcon title={t('common.sprintKeyActivity.milestone')} />,
        width: 48,
        dataIndex: 'milestone',
        render(value, record) {
          return (
            <span style={{ fontSize: 16 }}>
              {record.sprintKeyActivity?.milestone ? (
                <StarIcon className="dimmable" />
              ) : (
                <MinusOutlined
                  style={{ color: Colors.Grays.FRAMES_AND_LINES }}
                />
              )}
            </span>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.tags'),
        render: (timeStatus: SprintMitemTable_SkaTimeStatusFragment) => {
          const sortedTeamTags =
            sortTeamTags(timeStatus).map((t) => (
              <TagImage key={t.id} tag={t} />
            )) ?? [];

          const sortedInitiativeTags =
            sortInitiativeTags(features, timeStatus).map(
              ({ id, tag, metadata }) => (
                <InitiativeTag
                  key={id}
                  title={tag.title}
                  borderColor={tag.colorCode}
                  completed={metadata.completedAt !== null}
                  icon={Icons[tag.iconId as InitiativeIcon]}
                  archived={metadata.archived}
                />
              )
            ) ?? [];

          const tags = [...sortedInitiativeTags, ...sortedTeamTags];

          return (
            <div className="flx flx--gap--xs flx--wrap dimmable">
              {tags.length > 0 ? tags : <span>-</span>}
            </div>
          );
        },
      },
      ...(actionColumn ? [actionColumn] : []),
      {
        title: t('common.commitment', { count: filteredMitems?.length }),
        defaultSortOrder: 'ascend',
        excluded: !committable,
        dataIndex: 'commitment',
        render: (_, timeStatus) => {
          return (
            committable && (
              <div className="dimmable">
                <CommitColumn
                  sprintKaId={timeStatus.id}
                  ownerId={timeStatus.owner.id}
                />
              </div>
            )
          );
        },
      },
      Table.EXPAND_COLUMN,
    ];

  const compareTags = (
    a: SprintMitemTable_SkaTimeStatusFragment,
    b: SprintMitemTable_SkaTimeStatusFragment,
    sortDirection: SortOrder | undefined
  ) => {
    if (!sortDirection) return 0;
    const aHasMatch =
      (!untaggedOnly &&
        sprintKeyActivityHasTagMatch(
          features,
          a,
          andOrChoice,
          tagsSelectedForFiltering
        )) ||
      (sprintKeyActivityHasNoTags(a) && untaggedOnly);
    const bHasMatch =
      (!untaggedOnly &&
        sprintKeyActivityHasTagMatch(
          features,
          b,
          andOrChoice,
          tagsSelectedForFiltering
        )) ||
      (sprintKeyActivityHasNoTags(b) && untaggedOnly);
    if (aHasMatch === bHasMatch) {
      return 0;
    } else if (sortDirection === 'ascend') {
      return aHasMatch ? -1 : 1;
    } else {
      return bHasMatch ? 1 : -1;
    }
  };

  return (
    <Table
      className="SprintMitemTableCard__mitemTable"
      columns={sprintMitemColumns.filter((c) => !c.excluded)}
      dataSource={filteredMitems ?? []}
      onChange={(_pagination, _filter, sorter) => handleChange(sorter)}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (mitem) => <MitemDetails timeStatus={mitem} />,
        expandIcon: ({ expanded, onExpand, record }) => (
          <ExpandArrow
            onClick={(e) => onExpand(record, e)}
            expanded={expanded}
          />
        ),
      }}
      loading={loading}
      pagination={!pagination ? false : { size: 'small' }}
      rowKey={(timeStatus) => timeStatus.id}
      rowClassName={(timeStatus) =>
        (untaggedOnly && sprintKeyActivityHasTags(timeStatus)) ||
        (!untaggedOnly &&
          !sprintKeyActivityHasTagMatch(
            features,
            timeStatus,
            andOrChoice,
            tagsSelectedForFiltering
          ))
          ? 'MitemListEntry--dimmed'
          : ''
      }
    />
  );
};

interface MitemDetailsProps {
  timeStatus: SprintMitemTable_SkaTimeStatusFragment;
}

const MitemDetails = ({ timeStatus }: MitemDetailsProps) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const { data } = useQuery(GetMitemDetailsDocument, {
    variables: { teamId: timeStatus.teamId, mitemId: timeStatus.id },
  });

  const mostImportantItem = data?.mitem;

  const alignedMigs =
    mostImportantItem?.supportedMigs ??
    timeStatus.sprintKeyActivity?.supportedMigs ??
    [];

  const sortedInitiativeTags = sortInitiativeTags(features, timeStatus);
  const sortedTeamTags = sortTeamTags(timeStatus);

  return (
    <>
      <div className="flx">
        <div className="flx--1 SprintMitemTableCard__detailsSection">
          {alignedMigs.length > 0 &&
            alignedMigs.map((m) => (
              <div key={m.id} className="flx flx--column mb">
                <Typography.Text>
                  {t('SprintMitemTable.AlignedToMig')}
                </Typography.Text>
                <Typography.Text type="secondary">{m.name}</Typography.Text>
              </div>
            ))}

          {alignedMigs.length === 0 && (
            <div className="flx flx--column mb">
              <Typography.Text>{t('SprintMitemTable.noMig')}</Typography.Text>
              <Typography.Text type="secondary">
                {mostImportantItem?.noMigAssociation}
              </Typography.Text>
            </div>
          )}
          <div className="flx flx--column mb">
            <Typography.Text>{t('common.definitionOfDone')}</Typography.Text>
            <Typography.Text
              type="secondary"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {mostImportantItem?.definitionOfDone}
            </Typography.Text>
          </div>
          <div className="flx flx--column ">
            <Typography.Text>
              {t('common.sprintKeyActivity.tags')}
            </Typography.Text>
            <div className="flx flx--gap--xs flx--wrap">
              {sortedInitiativeTags.length > 0
                ? sortedInitiativeTags.map(({ tag, metadata }) => (
                    <InitiativeTag
                      key={tag.title}
                      title={tag.title}
                      borderColor={tag.colorCode}
                      icon={Icons[tag.iconId as InitiativeIcon]}
                      completed={metadata.completedAt !== null}
                      archived={metadata.archived}
                    />
                  ))
                : '-'}
              {sortedTeamTags.length > 0
                ? sortedTeamTags.map((l, i) => <TagImage tag={l} key={i} />)
                : '-'}
            </div>
          </div>
        </div>
        <div className="flx--1 SprintMitemTableCard__detailsSection">
          <div>
            <Typography.Text>{t('common.history')}</Typography.Text>
            <div className="mt">
              <MitemHistory
                teamId={timeStatus.teamId}
                mitemId={timeStatus.id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS_DETAILS = gql`
  query getMitemDetails($teamId: ID!, $mitemId: ID!) {
    mitem(teamId: $teamId, mitemId: $mitemId) {
      id
      teamId
      supportedMigs {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
      noMigAssociation
      definitionOfDone
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MITEM_TABLE_TIME_STATUS = gql`
  fragment SprintMitemTable_SkaTimeStatus on SkaTimeStatus {
    id
    teamId
    tenantId
    name
    deadline
    status
    owner {
      id
      email
      name
      displayName
    }
    sprintKeyActivity {
      ...SprintMitemTable_Mitem
      ...SprintMitemTableActions_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MITEM_TABLE_MITEM = gql`
  fragment SprintMitemTable_Mitem on Mitem {
    id
    teamId
    completed
    milestone
    status
    tags {
      id
      name
      backgroundColor
    }
    supportedInitiatives {
      id
      tag {
        title
        colorCode
        iconId
      }
      completed {
        value
      }
      archived
    }
    supportsInitiatives2 {
      id
      initiative {
        tag {
          title
          iconId
          colorCode
        }
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
        }
      }
    }
  }
`;
