import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { InitiativeDetailsWrapper } from './initiativeDetails/InitiativeDetailsWrapper';
import { Suspense } from 'react';
import { InitiativeStartPage } from './initiativeStartPage/InitiativeStartPage';
import { ErrorPage } from '../../../components/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { InitiativesNavigation } from './InitiativesNavigation/InitiativesNavigation';

export const InitiativePage = () => {
  const match = useRouteMatch();

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <div className="content mt">
        <div className="flx">
          <div
            style={{
              flexGrow: 1,
              minWidth: '250px',
              width: '250px',
              flex: '0 0 auto',
            }}
          >
            <Suspense fallback={'loading navigation'}>
              <InitiativesNavigation />
            </Suspense>
          </div>
          <div className="flx--1">
            <Switch>
              <Route exact path={match.path}>
                <Suspense fallback={<InitiativeStartPage.Skeleton />}>
                  <InitiativeStartPage />
                </Suspense>
              </Route>
              <Route path={`${match.path}/:initiativeId`}>
                <InitiativeDetailsWrapper />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
