import { InitiativeHeaderSection } from './headerSection/InitiativeHeaderSection';
import { OverviewSection } from './overviewSection/OverviewSection';
import { TitleSection } from './titleSection/TitleSection';

export const InitiativeDetailsSkeleton = () => {
  return (
    <div>
      <div className="mb--l">
        <TitleSection.Skeleton />
      </div>

      <InitiativeHeaderSection.Skeleton />

      <div className="divider mb--l mt--xl" />
      <OverviewSection.Skeleton />
      {/* 
      <div className="divider mb--l mt--xl" />
      <ProgressSection
        report={data.initiativeDetailedReport}
        initiativeReportNode={initiativeReportNode}
      /> 
      <div className="divider mb--l mt--xl" />
       <TeamContributionSection /> */}
    </div>
  );
};
