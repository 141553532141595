import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { TeamMemberTable } from './TeamMemberTable';

export const TeamMembersPage = () => {
  const { t } = useTranslation();
  const { teamId } = useParams<{ teamId: string }>();

  return (
    <div>
      <Helmet title={t('TeamMembersPage.titleMenu.members')} />
      <TeamMemberTable teamId={teamId} />
    </div>
  );
};
