import './GantishSection.less';
import { Gantish } from '../../../../../../../components/initiatives/Gantish/Gantish';
import { gql } from '@apollo/client';
import { InitiativeDetailsTreeNode } from '../../../InitiativeDetails';

interface Props {
  initiativeReportNode: InitiativeDetailsTreeNode;
}

export const GantishSection = ({ initiativeReportNode }: Props) => {
  return (
    <>
      <div className="GantishSection__gantishCard mb--l">
        <Gantish initiativeReportNode={initiativeReportNode} />
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment GantishSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
      activities {
        completed
        overdue
        upcoming
      }
    }
    ...Gantish__InitiativeDetailedReportResponse
  }
`;
