import { useTranslation } from 'react-i18next';
import './PreviewGraphLegends.less';
import cx from 'classnames';

interface Props {
  goal?: number | null;
  progress?: number | null;
}

export const PreviewGraphLegends = ({ goal, progress }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="PreviewGraphLegends flx">
      <div className="mr--l">
        <span className="txt--secondary">{t('common.goal')}: </span>
        <span className="PreviewGraphLegends__goal">{goal}</span>
      </div>
      <div>
        <span className="txt--secondary">
          {t('PreviewGraphLegends.progress')}:{' '}
        </span>
        <span
          className={cx('PreviewGraphLegends__completed', {
            'txt--disabled': !progress,
          })}
        >
          {progress ? progress : '--'}
        </span>
      </div>
    </div>
  );
};
