import { gql } from '@apollo/client';
import cx from 'classnames';
import { MilestoneCard_MilestoneFragment } from '../../../../../../../generated/graphql';
import './MilestoneCard.less';
import { Divider, Modal, Typography } from 'antd';
import { ProgressStats } from '../../../../../../../components/initiatives/ProgressStats';
import { MilestoneDetails } from '../../../../../../company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/milestoneActions/components/MilestoneDetails';
import { Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  milestone: MilestoneCard_MilestoneFragment;
}

export const MilestoneCard = ({ milestone }: Props) => {
  const { name, metadata } = milestone;
  const [showDetails, setShowDetails] = useState(false);
  const { status } = metadata;
  const stats = {
    completed: -1,
    overdue: -1,
    upcoming: -1,
  };

  return (
    <div>
      <Modal
        open={showDetails}
        footer={null}
        onCancel={() => setShowDetails(false)}
      >
        <ErrorBoundary FallbackComponent={MilestoneDetails.Error}>
          <Suspense fallback={<MilestoneDetails.Skeleton />}>
            <MilestoneDetails milestoneId={milestone.id} />
          </Suspense>
        </ErrorBoundary>
      </Modal>
      <div
        className={cx('MilestoneCard', `MilestoneCard--${status}`)}
        onClick={() => setShowDetails(true)}
      >
        <Typography.Text strong>{name}</Typography.Text>
        <div className="flx flx--gap--xs flx--wrap mt--m txt--secondary flx--jc-space-between">
          Activities
          <ProgressStats stats={stats} />
        </div>
        <Divider className="mt--s mb--s" />
        <div className="flx flx--jc-space-between flx--ai-center">
          <div className="flx">actions</div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MILESTONE_CARD__MILESTONE = gql`
  fragment MilestoneCard_Milestone on Milestone {
    id
    deadlineAt
    description
    name
    metadata {
      completedAt
      status
    }
  }
`;
