import Table, { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../../../../icons/overviewIcons/CheckIcon';
import { Colors } from '../../../../componentLibrary/Colors';
import { WarningIcon } from '../../../../../icons/performanceIcons/WarningIcon';
import { UpcomingEventIcon } from '../../../../../icons/overviewIcons/UpcomingEventIcon';
import { gql, useSuspenseQuery } from '@apollo/client';
import { GetTeamForTeamContributionTableDocument } from '../../../../../generated/graphql';
import { buildTeamContributionTree } from './utils/buildTeamContributionTree';
import { Unboxed } from '../../../../../services/typeHelpers';

export const TeamContributionTable = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(GetTeamForTeamContributionTableDocument);

  // We can uncomment after we start filtering by team, then also add filterByTeamId as a prop

  // const startWithParentId =
  //   data.teams.find((t) => t.id === filterByTeamId)?.parentTeamId ?? null;

  const teamTreeData = buildTeamContributionTree(data.teams);

  const columns: ColumnsType<Unboxed<typeof teamTreeData>> = [
    {
      title: t('TeamContributionTable.team'),
      dataIndex: 'name',
      key: 'id',
      render: (_, record) => {
        return <>{record.team.name}</>;
      },
    },
    {
      title: (
        <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
      ),
      key: 'Completed',
      width: 90,
      render: (_, record) => {
        return <>{record.contributionSummary.completed}</>;
      },
    },
    {
      title: (
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      ),
      key: 'Overdue',
      width: 90,

      render: (_, record) => {
        return <>{record.contributionSummary.overdue}</>;
      },
    },
    {
      title: (
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
      ),
      key: 'planned',
      width: 90,
      render: (_, record) => {
        return <>{record.contributionSummary.planned}</>;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey="id"
      pagination={false}
      dataSource={teamTreeData}
      expandable={{
        expandRowByClick: true,
      }}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAMS_TEAM_CONTRIBUTION_TABLE = gql`
  query getTeamForTeamContributionTable {
    teams(teamFilter: { active: true }) {
      name
      id
      created
      umbrellaTeam
      parentTeamId
    }
  }
`;
