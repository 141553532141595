import { Drawer, Form, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import { DrawerTitle } from '../../../../components/DrawerTitle';
import { useInitiativeLazy } from '../../../../hooks/useInitiative';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCreateInitiative } from './hooks/useCreateInitiative';
import { SubInitiativeForm } from './createSubInitiative/SubInitiativeForm';
import { howweErrorParser } from '../../../../services/howweErrorParser';
import { Btn } from '../../../../components/Button';
import { PureQueryOptions, RefetchQueriesFunction } from '@apollo/client';

interface Props {
  initiativeDomainId: { teamId: string; itemId: string };
  initiativeFullId: string;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  className?: string;
  disabled?: boolean;
}

export const CreateSubInitiativeDrawer = ({
  initiativeDomainId,
  initiativeFullId,
  refetchQueries,
  className,
  disabled,
}: Props) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const [fetchInitiative, { data, loading }] = useInitiativeLazy();

  useEffect(() => {
    if (initiativeDomainId && visible === true) {
      fetchInitiative({
        variables: {
          teamId: initiativeDomainId.teamId,
          initiativeId: initiativeDomainId.itemId,
        },
      });
    }
  }, [initiativeDomainId, fetchInitiative, visible]);

  const { createInitiative, loading: createLoader } = useCreateInitiative();

  let howweErrors;

  const handleSubmit = () => {
    if (data) {
      const colorCode = data.initiative.tag.colorCode;
      const iconId = data.initiative.tag.iconId;

      form.validateFields().then((values) => {
        const { teamId, description, name, tagTitle } = values;
        const migsIds = values.migs as string[];
        const objectives: string[] = values.objectives ?? [];

        createInitiative({
          variables: {
            teamId: teamId,
            initiative: {
              description: description,
              objectives: objectives
                .filter((o) => !!o)
                .map((objective: string) => ({
                  text: objective,
                })),
              supportsInitiatives: [{ id: initiativeFullId }],
              supportsMigs: migsIds
                .map((migId) => ({ id: migId }))
                .filter((mig) => mig.id !== ''),
              name: name,
              tag: {
                colorCode: colorCode,
                iconId: iconId,
                title: tagTitle,
              },
            },
          },
          refetchQueries,
          onCompleted: (data) => {
            showNotification('success', {
              message: (
                <strong>
                  {t('common.initiative.createSuccess', {
                    name: data.createInitiative.name,
                  })}
                </strong>
              ),
            });
            form.resetFields();
            setVisible(false);
            history.push(
              `/company/initiatives/${data.createInitiative.domainId.itemId}`
            );
          },
          onError: (e) => {
            howweErrors = howweErrorParser(e);

            showNotification('error', {
              message: (
                <strong>
                  <ul>
                    {howweErrors?.map((e, i) => (
                      <li key={i}>{e.translation}</li>
                    ))}
                  </ul>
                </strong>
              ),
            });
          },
        });
      });
    }
  };

  return (
    <div>
      <Tooltip
        placement="topRight"
        title={
          disabled
            ? t('CreateSubInitiativeDrawer.completedOrArchivedInfo')
            : null
        }
      >
        <Btn
          className={className}
          disabled={disabled}
          icon={<PlusCircleFilled />}
          type="link"
          onClick={() => setVisible(true)}
        >
          {t('CreateSubInitiativeDrawer.createSubInitiative')}
        </Btn>
      </Tooltip>

      <Drawer
        placement="right"
        closable={true}
        width={400}
        open={visible}
        onClose={() => setVisible(false)}
        title={
          <DrawerTitle>
            {t('CreateSubInitiativeDrawer.createSubInitiative')}
          </DrawerTitle>
        }
      >
        <SubInitiativeForm
          loading={loading}
          initialValue={data?.initiative}
          form={form}
        />

        <Btn
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
          loading={createLoader}
          disabled={loading}
        >
          {t('common.create')}
        </Btn>
      </Drawer>
    </div>
  );
};
