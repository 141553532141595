import { useTranslation } from 'react-i18next';

import { InitiativeGraph } from './components/InitiativeGraph/InitiativeGraph';
import Skeleton from 'react-loading-skeleton';

export const InitiativeHeaderSectionSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="flx">
      <div className="flx--3 pr--xxl card mr--xl pa--xl">
        <h3 className="bold mb">
          {t('InitiativeHeaderSection.purposeAndObjectives')}
        </h3>
        <div className="preserveLinebreaks">{<Skeleton count={10} />}</div>
      </div>
      <div className="flx--1 card pa--xl">
        <div className="mb--l">
          <h5 className="txt--secondary">
            {t('InitiativeHeaderSection.statusLabel')}
          </h5>
          <h3>
            <Skeleton />
          </h3>
        </div>
        <div className="mb--xl">
          <InitiativeGraph height={100} periodData={[]} />
        </div>
        <div className="mb--l">
          <h5 className="txt--secondary mb--xs">
            {t('InitiativeHeaderSection.financialValueLabel')}
          </h5>
          <Skeleton />
        </div>
        <div className="mb--l">
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.accountable')}
          </h5>
          <Skeleton />
        </div>
        <div className="mb--l">
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.supportsMig')}
            <Skeleton />
          </h5>
        </div>

        <div>
          <h5 className="txt--secondary mb">
            {t('InitiativeHeaderSection.detailedData')}
          </h5>
          <div className="flx flx--jc-space-between">
            {t('InitiativeHeaderSection.subInitiatives')}
            <div>
              <Skeleton />
            </div>
          </div>
          <div className="flx flx--jc-space-between">
            {t('InitiativeHeaderSection.startDate')}
            <div>
              <Skeleton />
            </div>
          </div>
          <div className="flx flx--jc-space-between">
            {t('InitiativeHeaderSection.endDate')}
            <div>
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
