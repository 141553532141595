import {
  EditOutlined,
  EllipsisOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Card, Dropdown, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArchiveOutlinedIcon } from '../../../icons/ArchiveIcon';
import { MigCard } from '../../mig/MigCard';
import { MigModalState } from '../MigSetupPage';
import { Btn } from '../../Button';
import { MigsWithGraphsCardsMigFragment } from '../../../generated/graphql';
import { gql } from '@apollo/client';

interface Props {
  setMigModalState: (setMigModalState: MigModalState | null) => void;
  migs: MigsWithGraphsCardsMigFragment[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIGS_WITH_GRAPHS_CARD_FRAGMENT = gql`
  fragment MigsWithGraphsCardsMig on MigV2 {
    status
    isMirrored(teamId: $teamId)
    domainId {
      tenantId
    }
    ...MigCardMig
  }
`;

export const MigsWithGraphsCards = ({ setMigModalState, migs }: Props) => {
  const { t } = useTranslation();
  const activeMigCards = [
    migs?.map((mig) => (
      <Card.Grid className={'pa--none '} hoverable={false} key={mig.id}>
        <MigCard
          mig={mig}
          showDescription={false}
          graphHeight={120}
          action={
            <Dropdown
              menu={{
                items: mig.isMirrored
                  ? [
                      {
                        key: 'RemoveMirrorMig',
                        label: t('common.mig.removeMirrorMig'),
                        icon: <StopOutlined />,
                        onClick: () =>
                          setMigModalState({
                            type: 'RemoveMirrorMig',
                            domainId: mig.domainId,
                          }),
                      },
                    ]
                  : [
                      {
                        key: 'EditMig',
                        icon: (
                          <EditOutlined className="keyActivityPage__icon" />
                        ),
                        label: t('common.edit'),
                        onClick: () =>
                          setMigModalState({
                            type: 'EditMig',
                            id: mig.domainId.itemId,
                          }),
                      },
                      {
                        key: 'UpdateStatusMig',
                        icon: (
                          <ArchiveOutlinedIcon className="keyActivityPage__icon" />
                        ),
                        label: t('common.archive'),
                        onClick: () =>
                          setMigModalState({
                            type: 'UpdateMigStatus',
                            id: mig.domainId.itemId,
                            status: mig.status,
                          }),
                      },
                    ],
              }}
              placement="bottom"
            >
              <Btn size="small" icon={<EllipsisOutlined rotate={90} />} />
            </Dropdown>
          }
        />
      </Card.Grid>
    )),
  ];

  if (activeMigCards.length === 0) {
    <Card size="small" bodyStyle={{ padding: 0, minHeight: '158px' }} bordered>
      <NoActiveMigs />
    </Card>;
  }

  return (
    <Card size="small" bodyStyle={{ padding: 0, minHeight: '158px' }} bordered>
      {activeMigCards}
    </Card>
  );
};

const NoActiveMigs = () => {
  const { t } = useTranslation();
  return (
    <div className="center-content flx">
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span className="txt--secondary">
            {t('TeamPerformanceMigCard.noInProgressMig')}
          </span>
        }
      />
    </div>
  );
};
