import { Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeyActivityModalState } from '../KeyActivitySetupPage';
import { PublishStatus } from '../../../generated/graphql';
import { useClosestPeriods } from '../../../hooks/useClosestPeriods';
import { TeamKeyActivitiesPreview } from './hooks/useTeamKeyActivitiesPreview';
import { SprintTermCard } from './activeKeyActivitiesCard/SprintTermCard';

import { WeeklyKeyActivityCard } from './activeKeyActivitiesCard/WeeklyKeyActivityCard';

interface Props {
  keyActivities: TeamKeyActivitiesPreview;
  setKeyActivityModalState: (
    keyActivityModalState: KeyActivityModalState | null
  ) => void;
  teamId: string;
  tenantId?: string;
}

export const ActiveKeyActivitiesCard = ({
  keyActivities: { weeklyKeyActivities, sprintTerms },
  setKeyActivityModalState,
  teamId,
  tenantId,
}: Props) => {
  const { lastFinishedPeriod } = useClosestPeriods(teamId, tenantId);
  const activeWeeklyKeyActivities =
    weeklyKeyActivities?.filter((wka) => wka.status === PublishStatus.ACTIVE) ??
    [];
  const activeSprintTerms =
    sprintTerms?.filter((st) => st.status === PublishStatus.ACTIVE) ?? [];

  const numberOfActiveKeyActivities =
    activeWeeklyKeyActivities.length + activeSprintTerms.length;

  const activeKeyActivities = [
    ...activeWeeklyKeyActivities.map((akpi) => (
      <Card.Grid className={'pa--none '} hoverable={false} key={akpi.name}>
        <WeeklyKeyActivityCard
          keyActivityId={akpi.id}
          teamId={teamId}
          tenantId={tenantId}
          setKeyActivityModalState={setKeyActivityModalState}
        />
      </Card.Grid>
    )),
    ...activeSprintTerms.map((term) => (
      <Card.Grid className={'pa--none '} hoverable={false} key={term.id}>
        <SprintTermCard
          termId={term.id}
          teamId={teamId}
          tenantId={tenantId}
          highlightedPeriod={lastFinishedPeriod?.timePeriodEndDate}
          setKeyActivityModalState={setKeyActivityModalState}
        />
      </Card.Grid>
    )),
  ];
  const content =
    numberOfActiveKeyActivities === 0 ? (
      <NoActiveKeyActivity />
    ) : (
      activeKeyActivities
    );
  return (
    <Card
      data-intercom-target="Active Key Activities"
      size="small"
      bodyStyle={{ padding: 0, minHeight: '158px' }}
      bordered
    >
      {content}
    </Card>
  );
};

const NoActiveKeyActivity = () => {
  const { t } = useTranslation();
  return (
    <div className="center-content flx">
      <Typography.Text type="secondary">
        {t('TeamPerformanceAkpiCard.noData')}
      </Typography.Text>
    </div>
  );
};
