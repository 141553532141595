import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StartingPointIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 20 20"
  >
    <path
      d="M6.667 6.667a3.21 3.21 0 0 0-2.355.979A3.21 3.21 0 0 0 3.333 10c0 .917.327 1.701.98 2.354a3.21 3.21 0 0 0 2.354.98 3.21 3.21 0 0 0 2.354-.98A3.21 3.21 0 0 0 10 10a3.21 3.21 0 0 0-.98-2.354 3.21 3.21 0 0 0-2.353-.98Zm0-1.667c1.25 0 2.336.396 3.26 1.188a4.834 4.834 0 0 1 1.677 2.979h6.73v1.666h-6.73a4.834 4.834 0 0 1-1.677 2.98C9.003 14.604 7.917 15 6.667 15c-1.39 0-2.57-.486-3.542-1.458-.972-.973-1.458-2.153-1.458-3.542 0-1.389.486-2.57 1.458-3.542S5.278 5 6.667 5Z"
      fill="currentColor"
    />
  </svg>
);

export const StartingPointIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={StartingPointIconSvg} {...props} />
);
