type HierarchicalTeam = { id: string; parentTeamId?: string | null };

type TeamTableSummary = {
  completed: number;
  overdue: number;
  planned: number;
};

export type ContributionTeam<T extends HierarchicalTeam> = {
  team: T;
  id: string;
  parentTeamId?: string | null;
  children?: ContributionTeam<T>[] | null;
  isTeamContributing: boolean;
  numberOfDescendingTeamsContributing: number;
  descendantsContributionSummary: TeamTableSummary;
  contributionSummary: TeamTableSummary;
};

export const buildTeamContributionTree = <T extends HierarchicalTeam>(
  teams: T[]
) => {
  const teamTreeDataInitialValue: ContributionTeam<T>[] =
    teams.map((team) => ({
      team,
      id: team.id,
      parentTeamId: team.parentTeamId,
      isTeamContributing: false,
      numberOfDescendingTeamsContributing: 0,
      contributionSummary: {
        completed: 0,
        overdue: 0,
        planned: 0,
      },
      descendantsContributionSummary: {
        completed: 0,
        overdue: 0,
        planned: 0,
      },
    })) ?? [];

  return _buildTeamContributionTree(teamTreeDataInitialValue, null);
};

const _buildTeamContributionTree = <T extends HierarchicalTeam>(
  teams: ContributionTeam<T>[],
  parentId: string | null = null
  //   teamsSummary: TeamSummary[]
): ContributionTeam<T>[] => {
  const result: ContributionTeam<T>[] = [];

  const teamsFilteredByParentId = teams.filter(
    (team) => team.parentTeamId === parentId
  );

  teamsFilteredByParentId.forEach((team) => {
    const children = _buildTeamContributionTree(teams, team.id);

    let node: ContributionTeam<T> = {
      ...team,
      numberOfDescendingTeamsContributing: 0,
      children: null as ContributionTeam<T>[] | null,
    };

    // Set to true until backend is ready
    node.isTeamContributing = true; //!!teamSummary;

    if (children.length > 0) {
      node.children = children;

      const childrenContribution = children.reduce(
        (acc, child) => {
          node.numberOfDescendingTeamsContributing +=
            child.numberOfDescendingTeamsContributing +
            (child.isTeamContributing ? 1 : 0);
          const completed =
            acc.completed +
            child.contributionSummary.completed +
            child.descendantsContributionSummary.completed;
          const overdue =
            acc.overdue +
            child.contributionSummary.overdue +
            child.descendantsContributionSummary.overdue;
          const planned =
            acc.planned +
            child.contributionSummary.planned +
            child.descendantsContributionSummary.planned;

          return {
            completed,
            overdue,
            planned,
          };
        },
        { completed: 0, overdue: 0, planned: 0 }
      );

      node.descendantsContributionSummary = childrenContribution;
    }
    // if (teamSummary?.activitiesSummary != null) {
    //   node.contributionSummary = {
    //     onTrackPercentage: teamSummary.activitiesSummary.onTrackPercentage,
    //     completed: teamSummary.activitiesSummary.completed.total,
    //     expected: teamSummary.activitiesSummary.expectedToBeCompleted.total,
    //     overdue: teamSummary.activitiesSummary.overdue.total,
    //     planned: teamSummary.activitiesSummary.planned.total,
    //   };
    // }

    if (
      node.isTeamContributing ||
      node.numberOfDescendingTeamsContributing > 0
    ) {
      result.push(node);
    }
  });
  return result;
};
