import Skeleton from 'react-loading-skeleton';

export const TitleSectionSkeleton = () => {
  return (
    <div className="flx--1">
      <h1>
        <Skeleton width={'30%'} />
      </h1>
    </div>
  );
};
