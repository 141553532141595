import { Drawer, Form } from 'antd';
import { DrawerTitle } from '../../../../components/DrawerTitle';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { InitiativeForm } from './InitiativeForm';
import { useHistory } from 'react-router-dom';
import { useCreateInitiative } from './hooks/useCreateInitiative';
import { howweErrorParser } from '../../../../services/howweErrorParser';
import { PureQueryOptions, RefetchQueriesFunction } from '@apollo/client';
import { Btn } from '../../../../components/Button';

interface Props {
  visible: boolean;
  onClose: () => void;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
}

export const CreateInitiativesDrawer = ({
  visible,
  onClose,
  refetchQueries,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const { createInitiative, loading } = useCreateInitiative();

  let howweErrors;

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { teamId, description, name, iconId, colorCode, tagTitle } = values;
      const migsIds = values.migs as string[];
      const objectives: string[] = values.objectives ?? [];

      createInitiative({
        variables: {
          teamId: teamId,
          initiative: {
            description: description,
            objectives: objectives
              .filter((o) => !!o)
              .map((objective: string) => ({
                text: objective,
              })),
            supportsInitiatives: [],
            supportsMigs: migsIds.map((migId) => ({ id: migId })),
            name: name,
            tag: {
              colorCode,
              iconId,
              title: tagTitle,
            },
          },
        },
        refetchQueries,
        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('common.initiative.createSuccess', {
                  name: data.createInitiative.name,
                })}
              </strong>
            ),
          });
          form.resetFields();
          onClose?.();
          history.push(
            '/company/initiatives/' +
              data.createInitiative.id +
              `?teamId=${data.createInitiative.domainId.teamId}`
          );
        },
        onError: (e) => {
          howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };

  return (
    <div>
      <Drawer
        placement="right"
        closable={true}
        width={400}
        open={visible}
        onClose={onClose}
        title={
          <DrawerTitle>
            {t('common.initiative.createInitiativeHeader')}
          </DrawerTitle>
        }
      >
        <InitiativeForm form={form} includeTagEditing={true} />
        <Btn
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
          loading={loading}
        >
          {t('common.create')}
        </Btn>
      </Drawer>
    </div>
  );
};
