import { Alert, Drawer, Form, Select, Tooltip, Typography } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  Action,
  CreateSkaForInitiativeDocument,
  GetInitiativeReportDocument,
  InitiativeContentFragment,
  SprintKaInput,
  TeamResourceType,
} from '../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { usePermissionForTeams } from '../../../../../../hooks/usePermissionForTeams';
import { SprintKaForm } from '../../../../../team/sprint/planning/components/SprintKaForm';
import { DrawerTitle } from '../../../../../../components/DrawerTitle';
import { stringSort } from '../../../../../../services/stringSort';
import { InfoCircleOutlined } from '@ant-design/icons';
import { showNotification } from '../../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../../services/howweErrorParser';
import { MIG_ASSOCIATION_OTHER } from '../../../../../team/sprint/planning/SprintPlanningPage';
import { standardDateFormat } from '../../../../../../services/dateFormats';
import { LearnMoreLink } from '../../../../../../components/LearnMoreLink';
import { useTenantDetails } from '../../../../../../hooks/useTenantDetails';

interface Props {
  contributingTeams: { id: string; name: string }[];
  initiative: InitiativeContentFragment;
}

export const CreateSprintKeyActivityDrawerWithButton = ({
  contributingTeams,
  initiative,
}: Props) => {
  const [teamId, setTeamId] = useState<string | undefined>();
  const { features } = useTenantDetails();
  const [form] = Form.useForm();
  const [showDrawer, setShowDrawer] = useState(false);
  const { t } = useTranslation();

  const { Option } = Select;
  const { data } = usePermissionForTeams(
    TeamResourceType.SPRINT_KA,
    Action.CREATE
  );

  const allowedTeams = useMemo(() => {
    return (
      contributingTeams.filter((ct) =>
        data?.permittedToPerformActionForTeam.permittedResources.some(
          (prt) => ct.id === prt.teamId
        )
      ) ?? []
    );
  }, [
    data?.permittedToPerformActionForTeam.permittedResources,
    contributingTeams,
  ]);

  const [createSkaForInitiative, { loading: createPending }] = useMutation(
    CreateSkaForInitiativeDocument,
    {
      refetchQueries: () => [
        {
          query: GetInitiativeReportDocument,
          variables: {
            teamId: initiative.team.id,
            initiativeId: initiative.domainId.itemId,
            forceUpdate: true,
          },
        },
      ],
      onCompleted: (data) => {
        showNotification('success', {
          message: t('CreateSprintKeyActivityDrawerWithButton.saveSuccess'),
          description: data.createSprintKeyActivity.name,
          placement: 'top',
        });
        form.resetFields();
      },

      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('CreateSprintKeyActivityDrawerWithButton.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
    }
  );

  const submit = (mitem: any, teamId: string) => {
    let tags = mitem.tags;
    let noMigAssociation: string | null | undefined = mitem.noMigAssociation;
    const { supportsMigId, supportsInitiativeIds, ...rest } = mitem;
    const supportsInitiativeIdsToBeSubmitted =
      (supportsInitiativeIds as (string | null | undefined)[]) ?? [];

    const skaToSubmit: SprintKaInput = {
      ...rest,
      tags,
      supportsMigIds:
        supportsMigId === MIG_ASSOCIATION_OTHER || supportsMigId == null
          ? []
          : [{ id: supportsMigId }],
      noMigAssociation,
      deadline: standardDateFormat(mitem.deadline),
    };

    if (features.teamInitiativesEnabled) {
      skaToSubmit.supportsInitiativeIds = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    if (features.tenantInitiativesEnabled) {
      skaToSubmit.supportsInitiative2Ids = supportsInitiativeIdsToBeSubmitted
        .filter((i) => i != undefined)
        .filter((i) => i !== '');
    }

    createSkaForInitiative({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
    });
  };

  const teamsOptions = allowedTeams
    .sort((a, b) => stringSort(a.name, b.name))
    .map((t) => (
      <Option title={t.name} key={t.id} value={t.id}>
        {t.name}
      </Option>
    ));

  const isAllowedToCreate = allowedTeams.length > 0;

  return (
    <div>
      {isAllowedToCreate && (
        <Tooltip
          placement="top"
          title={t('common.create')}
          mouseEnterDelay={0.7}
        >
          <Btn
            onClick={() => {
              setShowDrawer(true);
            }}
          >
            {t('CreateSprintKeyActivityDrawerWithButton.createKeyActivity')}
          </Btn>
        </Tooltip>
      )}
      <Drawer
        title={
          <DrawerTitle className="CreateMitemDrawer__title">
            {t('CreateSprintKeyActivityDrawerWithButton.modalTitle')}
          </DrawerTitle>
        }
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={420}
        destroyOnClose
        forceRender={true}
        drawerStyle={{
          borderLeft: '8px solid #eaeaea',
        }}
      >
        <h4 className="bold mb--s">{'Team Selector'}</h4>

        <Form.Item
          name="name"
          data-intercom-target="Sprint Key Activity Form Name Input"
        >
          <Select
            showSearch
            allowClear
            value={teamId}
            placeholder={t('common.searchTeamPlaceholder')}
            optionFilterProp="children"
            onChange={(value) => {
              form.resetFields();
              setTeamId(value);
            }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {teamsOptions}
          </Select>
        </Form.Item>

        {!teamId && (
          <Alert
            type="info"
            message={
              <>
                <Typography.Title level={4}>
                  <InfoCircleOutlined className="mr" />
                  {t(
                    'CreateSprintKeyActivityDrawerWithButton.informationTitle'
                  )}
                </Typography.Title>
                <p>
                  {t(
                    'CreateSprintKeyActivityDrawerWithButton.createRequirements.header'
                  )}

                  <ul className="mt">
                    <li className="mb--s">
                      {t(
                        'CreateSprintKeyActivityDrawerWithButton.createRequirements.leaderRequirement'
                      )}
                      <div>
                        <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28924-team-leader-privileges" />
                      </div>
                    </li>
                    <li>
                      {t(
                        'CreateSprintKeyActivityDrawerWithButton.createRequirements.joinedInitiativeRequirement'
                      )}
                      <div>
                        <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/111024-joining-teams-to-company-initiatives" />
                      </div>
                    </li>
                  </ul>
                </p>
              </>
            }
          ></Alert>
        )}

        {teamId && (
          <SprintKaForm
            key={teamId}
            teamId={teamId}
            isEdit={false}
            onSubmit={(ska) => submit(ska, teamId)}
            submitPending={createPending}
            onCancel={() => {
              form.resetFields();
              setShowDrawer(false);
            }}
            formRef={form}
          />
        )}
      </Drawer>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_SKA_FOR_INITIATIVE = gql`
  mutation createSkaForInitiative(
    $teamId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...CreateSprintKeyActivityDrawerWithButton__Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CreateSprintKeyActivityDrawerFragment = gql`
  fragment CreateSprintKeyActivityDrawerWithButton__Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportedInitiatives {
      id
      tag {
        title
        iconId
        colorCode
      }
    }
    supportsInitiatives2 {
      id
      initiative {
        tag {
          title
          iconId
          colorCode
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner {
      id
      email
      name
      displayName
      archivedAt
    }
    supportedMigs {
      id
      domainId {
        itemId
        teamId
      }
      name
    }
    teamId
  }
`;
