import { CheckIcon } from '../../../../../../../../../../icons/overviewIcons/CheckIcon';
import { GroupsIcon } from '../../../../../../../../../../icons/GroupsIcon';
import { PersonShieldIcon } from '../../../../../../../../../../icons/PersonShield';
import { AlignmentIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/EndingPointIcon';
import { TextAreaIconIcon } from '../../../../../../../../../../components/initiatives/InitiativeForm/Icons/TextAreaIcon';
import Skeleton from 'react-loading-skeleton';

export const MilestoneDetailsSkeleton = () => {
  return (
    <div>
      <div className="flx mb--xl bold">
        <CheckIcon className="mr--l txt--secondary font-size--lg" />
        <div className="fullWidth">
          <Skeleton />
        </div>
      </div>
      <div className="flx mb--xl">
        <TextAreaIconIcon className="mr--l txt--secondary font-size--lg" />
        <div className="fullWidth">
          <Skeleton count={3} />
        </div>
      </div>
      <div className="flx mb--xl">
        <EndingPointIcon className="mr--l txt--secondary font-size--lg" />
        <div className="fullWidth">
          <Skeleton />
        </div>
      </div>
      <div className="flx mb--xl">
        <PersonShieldIcon className="mr--l txt--secondary font-size--lg" />
        <div className="fullWidth">
          <Skeleton />
        </div>
      </div>
      <div className="flx mb--xl">
        <AlignmentIcon className="mr--l txt--secondary font-size--lg" />
        <div className="fullWidth">
          <Skeleton />
        </div>
      </div>
      <div className="flx mb--xl">
        <GroupsIcon className="mr--l txt--secondary font-size--lg" />
        <div className="fullWidth">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};
