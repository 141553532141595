import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../components/Button';
import { InitiativeValue } from '../../../../strategicPlan/components/Gigantish/components/GigantishLegendInitiative/components/InitiativeValue';
import { gql } from '@apollo/client';
import { FinancialPotential_Initiative2Fragment } from '../../../../../../generated/graphql';
import { BrowserUpdatedIcon } from '../../../../../../icons/BrowserUpdated';

interface Props {
  initiative: FinancialPotential_Initiative2Fragment;
}

export const FinancialPotential = ({ initiative }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {initiative.profit && (
        <div className="flx flx--jc-space-between">
          {t('common.profit')}
          <InitiativeValue value={initiative.profit} />
        </div>
      )}
      {initiative.revenue && (
        <div className="flx flx--jc-space-between">
          {t('common.revenue')}
          <InitiativeValue value={initiative.revenue} />
        </div>
      )}
      <Btn
        className="pl--none bold font-size--sm"
        size="small"
        icon={<BrowserUpdatedIcon style={{ fontSize: 16 }} />}
        type="link"
        onClick={() => alert('Not yet implemented')}
      >
        {t('FinancialPotential.updateFinancialValues')}
      </Btn>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FINANCIAL_POTENTIAL_FRAGMENT = gql`
  fragment FinancialPotential_Initiative2 on Initiative2 {
    profit {
      potential
      current
    }
    revenue {
      potential
      current
    }
  }
`;
