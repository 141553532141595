import Icon, {
  IconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

const BrowserUpdatedSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M4.4 13.466v-1.2l.6-.6H3.2c-.33 0-.613-.117-.848-.353A1.156 1.156 0 0 1 2 10.466v-6.6c0-.33.118-.612.353-.847.234-.235.517-.353.847-.353H8v1.2H3.2v6.6h9.6v-1.8H14v1.8c0 .33-.117.613-.352.848a1.156 1.156 0 0 1-.848.352H11l.6.6v1.2H4.4Zm5.4-3.6-3-3 .84-.84L9.2 7.571V2.666h1.2v4.905l1.56-1.545.84.84-3 3Z" />
    </g>
  </svg>
);

export const BrowserUpdatedIcon = (props: Omit<IconComponentProps, 'ref'>) => (
  <Icon component={BrowserUpdatedSvg} {...props} />
);
