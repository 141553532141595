import { Suspense, useState } from 'react';
import { Btn } from '../../Button';
import { Modal, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ManageStratPlanAdmins } from './components/ManageStratPlanAdmins';
import { ManufactoringIcon } from '../../../icons/ManufacturingIcon';
import { ManageCompanyMigs } from './components/manageCompanyMigs/ManageCompanyMigs';
import { ManageCompanyInitiatives } from './components/manageCompanyInitiatives/ManageCompanyInitiatives';

export const StrategicPlanAdminModal = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <Btn
        icon={<ManufactoringIcon style={{ fontSize: 18 }} />}
        onClick={() => setVisible(true)}
      >
        {t('StrategicPlanAdminModal.initiativeAdminPanel')}
      </Btn>
      <Modal
        width={870}
        title={t('StrategicPlanAdminModal.title')}
        open={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose
        footer={null}
      >
        <Tabs
          items={[
            {
              key: 'companyInitiatives',
              label: t('StrategicPlanAdminModal.companyInitiatives'),
              children: (
                <Suspense
                  fallback={
                    <Spin spinning>
                      <ManageCompanyInitiatives.Skeleton />
                    </Spin>
                  }
                >
                  <ManageCompanyInitiatives />
                </Suspense>
              ),
            },
            {
              key: 'companyMigs',
              label: t('StrategicPlanAdminModal.companyMigs'),
              children: (
                <Suspense
                  fallback={
                    <Spin spinning>
                      <ManageCompanyMigs.Skeleton />
                    </Spin>
                  }
                >
                  <ManageCompanyMigs />
                </Suspense>
              ),
            },
            {
              key: 'stratPlanAdmins',
              label: t('StrategicPlanAdminModal.manageAdmins'),
              children: <ManageStratPlanAdmins />,
            },
          ]}
        />
      </Modal>
    </div>
  );
};
