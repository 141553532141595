import { useState } from 'react';

interface ModalState {
  modalOpen: boolean;
  periodToCreateMitemIn?: string | null;
}

export const useCreateMitemModal = () => {
  const [modalState, setModalState] = useState<ModalState>({
    modalOpen: false,
  });

  const openCreateForm = (deadline?: string) => {
    setModalState({
      modalOpen: true,
      periodToCreateMitemIn: deadline,
    });
  };

  const closeAndClearDeadline = () => {
    setModalState({ modalOpen: false, periodToCreateMitemIn: null });
  };

  return {
    isOpen: modalState.modalOpen,
    periodToCreateMitemIn: modalState.periodToCreateMitemIn,
    openModal: openCreateForm,
    closeModal: closeAndClearDeadline,
  };
};

export type CreateMitemModal = ReturnType<typeof useCreateMitemModal>;
