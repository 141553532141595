import { Divider } from 'antd';
import { InitiativesColors } from '../../../../../styleVars';
import { Btn } from '../../../../Button';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { MarkInitiativeAsArchivedModalContentSkeleton } from './MarkInitiativeAsArchivedModalContent.skeleton';
import {
  EditMarkInitiativeAsArchivedModalContentDocument,
  GetMarkInitiativeAsArchivedModalContentDocument,
} from '../../../../../generated/graphql';
import { MarkAsArchivedIcon } from '../../icons/MarkAsArchivedIcon';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { ArchiveIcon } from '../../../../../icons/initiativeIcons/ArchiveIcon';

interface Props {
  initiativeId: string;
}

export const MarkInitiativeAsArchivedModalContent = ({
  initiativeId,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    GetMarkInitiativeAsArchivedModalContentDocument,
    { variables: { initiativeId: initiativeId }, fetchPolicy: 'no-cache' }
  );

  const [markAsArchived, { loading }] = useMutation(
    EditMarkInitiativeAsArchivedModalContentDocument,
    {
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('MarkInitiativeAsArchivedModalContent.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
    }
  );

  const handleMarkAsArchived = () => {
    markAsArchived({
      variables: {
        initiativeId: initiativeId,
        initiative: {
          archived: true,
          rev: data.tenantInitiative.rev,
        },
      },
    });
  };

  return (
    <div className="text-c flx flx--column">
      <h3>{t('MarkInitiativeAsArchivedModalContent.title')}</h3>
      {data.tenantInitiative.name}
      <div className="mt">
        <MarkAsArchivedIcon
          style={{ color: InitiativesColors.orange, fontSize: 70 }}
        />
      </div>
      <div className="mt">
        {t('MarkInitiativeAsArchivedModalContent.infoText')}
      </div>
      <Divider />
      <div>
        <Btn
          loading={loading}
          onClick={handleMarkAsArchived}
          icon={<ArchiveIcon />}
          type="success"
        >
          {t('MarkInitiativeAsArchivedModalContent.markAsArchived')}
        </Btn>
      </div>
    </div>
  );
};

MarkInitiativeAsArchivedModalContent.Skeleton =
  MarkInitiativeAsArchivedModalContentSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_INITIATIVE_FOR_MARK_AS_ARCHIVED = gql`
  mutation editMarkInitiativeAsArchivedModalContent(
    $tenantId: ID
    $initiativeId: ID!
    $initiative: UpdateInitiative2Input!
  ) {
    updateTenantInitiative(
      tenantId: $tenantId
      initiativeId: $initiativeId
      initiative: $initiative
    ) {
      id
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE_FOR_MARK_AS_ARCHIVED = gql`
  query getMarkInitiativeAsArchivedModalContent(
    $tenantId: ID
    $initiativeId: ID!
  ) {
    tenantInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      name
      rev
      metadata {
        archived
      }
    }
  }
`;
