import { stringSort } from '../../../../services/stringSort';

export interface BaseInitiative {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  name: string;
  tag: {
    title: string;
    iconId: string;
    colorCode: string;
  };
  metadata: {
    archived: boolean;
    completedAt: string | null;
    supports: Array<{
      type: string;
      item: {
        id: string;
        domainId: {
          itemId: string;
          teamId?: string | null;
          tenantId: string;
        };
      };
    }>;
  };
}

export interface InitiativeNode<T = unknown> {
  id: string;
  key: string;
  children?: InitiativeNode<T>[];
  title: React.ReactNode;
  parentId?: string;
  parentIsCompletedOrArchived: boolean;
  baseInitiative: BaseInitiative;
  data: T;
}

export function initiativeTreeBuilder<T>(
  initiatives: InitiativeNode<T>[],
  parentId: string,
  parentPath: string,
  parentIsCompletedOrArchived: boolean = false
): InitiativeNode<T>[] {
  const result: InitiativeNode<T>[] = [];

  // Filter out initiatives that are children of the parent initiative
  const filteredInitiatives = initiatives.filter((i) =>
    i.baseInitiative.metadata.supports.some(
      (s) => s.type === 'initiative' && s.item.id === parentId
    )
  );

  // Recursively build the tree nodes
  filteredInitiatives.forEach((initiativeNode) => {
    // The path is the key of the node, and is used to identify the node in the tree, this must be unique based on the position of the node in the tree,
    // otherwise the tree will not render correctly
    const path =
      parentPath + '/' + initiativeNode.baseInitiative.domainId.itemId;

    const children = initiativeTreeBuilder(
      initiatives,
      initiativeNode.baseInitiative.id,
      path,
      parentIsCompletedOrArchived ||
        initiativeNode.baseInitiative.metadata.archived ||
        initiativeNode.baseInitiative.metadata.completedAt !== null
    );

    let node: InitiativeNode<T> = {
      ...initiativeNode,
      id: initiativeNode.baseInitiative.domainId.itemId,
      key: path,
      parentId: parentId,
      parentIsCompletedOrArchived: parentIsCompletedOrArchived,
    };

    if (children.length > 0) {
      node.children = children.sort((a, b) =>
        stringSort(a.baseInitiative.tag.title, b.baseInitiative.tag.title)
      );
    }

    result.push(node);
  });
  return result;
}

// This function finds all parents Id based on the nodeId.
// For example used to determine what nodes that should be expanded on render
export const getParentKeys = (
  treeNodes: InitiativeNode[],
  nodeId?: string,
  parentKeys: string[] = [],
  result: string[] = []
): string[] | null => {
  for (const node of treeNodes) {
    if (node.id === nodeId) {
      result.push(...parentKeys, node.key);
    }
    if (node.children) {
      getParentKeys(node.children, nodeId, [...parentKeys, node.key], result);
    }
  }
  return result.length > 0 ? result : null;
};
