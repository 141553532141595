import cx from 'classnames';
import { DurationStatus } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import './InitiativeStatus.less';

interface Props {
  status: DurationStatus;
  archived: boolean;
}

export const InitiativeStatus = ({ status, archived }: Props) => {
  const { t } = useTranslation();
  return (
    <span
      className={cx('InitiativeStatus', {
        'InitiativeStatus--completed': status === 'COMPLETED',
        'InitiativeStatus--upcoming': status === 'UPCOMING',
        'InitiativeStatus--overdue': status === 'OVERDUE',
        'InitiativeStatus--active': status === 'ACTIVE',
        'InitiativeStatus--archived': archived,
      })}
    >
      {archived
        ? t('common.initiative.archived')
        : t(`common.initiative.status.${status.toLowerCase()}`)}
    </span>
  );
};
