//eslint-disable-next-line howwe-rules/no-antd-buttons
import { Button, ConfigProvider } from 'antd';
import { ButtonProps as AntButtonProps } from 'antd/lib/button';
import { Colors } from '../appPages/componentLibrary/Colors';

type BaseProps = Omit<AntButtonProps, 'type'>;
type Props = BaseProps & {
  type?: AntButtonProps['type'] | 'callToAction' | 'success';
};

/*  
Important
    Only use ConfigProvider for overrides here if you want to change styling of a _specific_ button `type`
    and it is not possible to do so via the global AntD ConfigProvider located in `src/App.tsx`
*/

// We're calling it Btn to avoid confusion with the AntD Button component (and making it easier to auto import)
export const Btn = ({ type, ...props }: Props) => {
  if (type === 'callToAction') {
    return (
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorBorder: props.disabled
                ? Colors.Grays.DISABLED_LINES
                : Colors.Howwe.MAIN_ORANGE,
              colorPrimary: Colors.Howwe.MAIN_ORANGE,
              colorPrimaryHover: Colors.Action.CTA_BUTTON_HOVER,
            },
          },
        }}
      >
        <Button {...props} type="primary" />
      </ConfigProvider>
    );
  } else if (type === 'default' || type === undefined) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorBorder: props.disabled
              ? Colors.Grays.DISABLED_LINES
              : Colors.Action.BLUE, // have to add conditional rendering here since antd has same border color on all buttons, even the disabled ones.
            colorText: Colors.Action.BLUE,
            colorPrimaryHover: Colors.Action.HOVER_BLUE,
          },
        }}
      >
        <Button {...props} />
      </ConfigProvider>
    );
  } else if (type === 'primary') {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorBorder: props.disabled
              ? Colors.Grays.DISABLED_LINES
              : Colors.Action.BLUE, // have to add conditional rendering here since antd has same border color on all buttons, even the disabled ones.
          },
        }}
      >
        <Button type="primary" {...props} />
      </ConfigProvider>
    );
  } else if (type === 'success') {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorBorder: props.disabled
              ? Colors.Grays.DISABLED_LINES
              : Colors.Status.OK_GREEN, // have to add conditional rendering here since antd has same border color on all buttons, even the disabled ones.
            colorPrimary: Colors.Status.OK_GREEN,
            colorPrimaryHover: Colors.Action.CTA_BUTTON_HOVER, // have to add conditional rendering here since antd has same border color on all buttons, even the disabled ones.
          },
        }}
      >
        <Button type="primary" {...props} />
      </ConfigProvider>
    );
  } else return <Button {...props} type={type} />;
};
