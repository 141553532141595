import { useTranslation } from 'react-i18next';
import { MilestoneTable } from './components/milestoneTable/MilestoneTable';
import { CreateMilestoneButton } from './components/createMilestoneButton/CreateMilestoneButton';
import { gql } from '@apollo/client';
import {
  Action,
  InitiativeProgressSection__InitiativeDetailedReportResponseFragment,
} from '../../../../../generated/graphql';
import { InitiativeDetailsTreeNode } from '../InitiativeDetails';
import { Col, Row } from 'antd';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { useState } from 'react';
import { ProgessStatusFilter } from './components/ProgessStatusFilter';
import { PermissionChecker } from '../../../../../PermissionChecker';

interface Props {
  report: InitiativeProgressSection__InitiativeDetailedReportResponseFragment;
  initiativeReportNode: InitiativeDetailsTreeNode;
}

export const ProgressSection = ({ report, initiativeReportNode }: Props) => {
  const [statusFilters, setStatusFilters] = useState<{
    completed: boolean;
    overdue: boolean;
    upcoming: boolean;
  }>({ completed: true, overdue: true, upcoming: true });
  const { t } = useTranslation();
  const [milestonesOrActivities, setMilestonesOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );

  const stats =
    milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones
      ? report.accumulatedStats.milestones
      : report.accumulatedStats.activities;

  const filteredMilestones =
    initiativeReportNode.data.accumulatedMilestones.milestones.filter((m) => {
      const status = m.milestone.metadata.status.toLowerCase();
      return statusFilters[status as keyof typeof statusFilters];
    });

  return (
    <div>
      <div className="mb mt flx flx--jc-space-between flx--ai-center">
        <h3>{t('ProgressSection.title')}</h3>
        <PermissionChecker
          resourceOwner={{
            type: 'TenantResource',
            requestedAction: {
              resource: 'milestone',
              action: Action.CREATE,
            },
          }}
        >
          <div>
            <CreateMilestoneButton initiative={report.initiative} />
          </div>
        </PermissionChecker>
      </div>
      <div className="mb mt flx flx--jc-space-between flx--ai-center">
        <div>
          <MilestoneOrActiveToggle
            value={milestonesOrActivities}
            onChange={setMilestonesOrActivities}
          />
        </div>
      </div>
      <Row gutter={[18, 18]} className="mb--xl">
        <Col xs={12} xl={8}>
          <ProgessStatusFilter
            stats={stats}
            status="completed"
            checked={statusFilters.completed}
            onChange={() =>
              setStatusFilters({
                ...statusFilters,
                completed: !statusFilters.completed,
              })
            }
          />
        </Col>
        <Col xs={12} xl={8}>
          <ProgessStatusFilter
            stats={stats}
            status="overdue"
            checked={statusFilters.overdue}
            onChange={() =>
              setStatusFilters({
                ...statusFilters,
                overdue: !statusFilters.overdue,
              })
            }
          />
        </Col>
        <Col xs={12} xl={8}>
          <ProgessStatusFilter
            stats={stats}
            status="upcoming"
            checked={statusFilters.upcoming}
            onChange={() =>
              setStatusFilters({
                ...statusFilters,
                upcoming: !statusFilters.upcoming,
              })
            }
          />
        </Col>
      </Row>

      <MilestoneTable milestonesWithStats={filteredMilestones} />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_PROGRESS_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeProgressSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    initiative {
      ...CreateMilestoneButton__Initiative2
      domainId {
        itemId
        tenantId
      }
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
      activities {
        completed
        overdue
        upcoming
      }
    }
    ...MilestoneTable__InitiativeDetailedReportResponse
  }
`;
