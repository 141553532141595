import Icon from '@ant-design/icons';
import React from 'react';

const ExternalLinkIconSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 15 15"
  >
    <g fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          d="M219 320.5v8c0 .276-.224.5-.5.5h-13c-.276 0-.5-.224-.5-.5v-13c0-.276.224-.5.5-.5h8c.276 0 .5.224.5.5s-.224.5-.5.5H206v12h12v-7.5c0-.276.224-.5.5-.5s.5.224.5.5zm.962-6.187c-.036-.08-.092-.149-.163-.2-.016-.012-.033-.024-.05-.034-.074-.048-.16-.076-.249-.079h-4c-.276 0-.5.224-.5.5s.224.5.5.5h2.793l-6.147 6.146c-.196.196-.196.512 0 .708.196.196.512.196.708 0l6.146-6.147v2.793c0 .276.224.5.5.5s.5-.224.5-.5v-4c0-.064-.013-.128-.038-.187z"
          transform="translate(-205.000000, -314.000000)"
        />
      </g>
    </g>
  </svg>
);

export const ExternalLinkIcon = (props: any) => (
  <Icon component={ExternalLinkIconSvg} {...props} />
);
