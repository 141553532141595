import {
  BaseInitiative,
  InitiativeNode,
  initiativeTreeBuilder,
} from '../initiativeTreeBuilder';
import { InitiativeTitle } from './InitiativeTitle';

type TopInitiativeReportInput<T, U> = {
  initiative: T;
  allSubInitiatives: U[];
};

export function navigationTreeBuilder<
  T extends BaseInitiative,
  U extends BaseInitiative,
>(initiatives: TopInitiativeReportInput<T, U>[]): InitiativeNode[] {
  const initiativesTree: InitiativeNode[] =
    initiatives.map((i) => {
      const subInitiativeNodes: InitiativeNode[] = i.allSubInitiatives.map(
        (si) => ({
          id: si.id,
          key: si.id,
          title: (
            <InitiativeTitle
              tag={si.tag}
              domainId={si.domainId}
              isTopInitiative={false}
              metadata={si.metadata}
            />
          ),
          parentIsCompletedOrArchived: false,
          baseInitiative: si,
          data: null,
        })
      );

      return {
        id: i.initiative.domainId.itemId,
        key: i.initiative.domainId.itemId,
        title: (
          <InitiativeTitle
            tag={i.initiative.tag}
            domainId={i.initiative.domainId}
            isTopInitiative={true}
            metadata={i.initiative.metadata}
          />
        ),
        baseInitiative: i.initiative,
        children: initiativeTreeBuilder(
          subInitiativeNodes,
          i.initiative.id,
          i.initiative.domainId.itemId,
          i.initiative.metadata.archived
        ),
        parentIsCompletedOrArchived: false,
        data: null,
      };
    }) ?? [];

  return initiativesTree;
}
