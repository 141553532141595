import dayjs from 'dayjs';
import { GigantishTreeNode, daysToPx } from '../Gigantish';
import './GigantishChartInitiative.less';
import cx from 'classnames';
import { DurationStatus } from '../../../../../../generated/graphql';

interface Props {
  initiative: GigantishTreeNode;
  expandedInitiatives: string[];
  boundary: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
  windowStart: dayjs.Dayjs;
  isSubInitiative?: boolean;
}

export const GigantishChartInitiative = ({
  initiative,
  windowStart,
  expandedInitiatives,
  boundary,
  isSubInitiative = false,
}: Props) => {
  const daysLong = dayjs(
    initiative.data.initiative.endAt ??
      initiative.data.initiative.metadata.completedAt ??
      boundary.end.subtract(1, 'month')
  ).diff(initiative.data.initiative.startAt ?? boundary.start, 'days');

  const daysSinceBoundaryStart = dayjs(initiative.data.initiative.startAt).diff(
    boundary.start,
    'days'
  );

  const isExpanded = expandedInitiatives.includes(initiative.id);
  const status = initiative.data.initiative.metadata.status;

  return (
    <div className="">
      <div
        key={initiative.id}
        className={cx('GigantishChartInitiative__row', {
          'GigantishChartInitiative__row--small': isSubInitiative,
        })}
      >
        <div
          className={cx(
            'GigantishChartInitiative__bar  gantishShared-AppendAnimation',
            {
              'GigantishChartInitiative__bar--completed':
                status === DurationStatus.COMPLETED,
              'GigantishChartInitiative__bar--archived':
                initiative.data.initiative.metadata.archived,
              'GigantishChartInitiative__bar--upcoming':
                status === DurationStatus.UPCOMING,
              'GigantishChartInitiative__bar--overdue':
                status === DurationStatus.OVERDUE,
              'GigantishChartInitiative__bar--active':
                status === DurationStatus.ACTIVE,
            }
          )}
          title={`${initiative.data.initiative.startAt} to ${initiative.data.initiative.endAt} ${status}`}
          style={{
            marginLeft: daysToPx(daysSinceBoundaryStart),
            width: daysToPx(daysLong),
          }}
        >
          {initiative.baseInitiative.name}
        </div>
      </div>
      {isExpanded &&
        initiative.children?.map((subInitiative) => (
          <GigantishChartInitiative
            key={subInitiative.id}
            initiative={subInitiative}
            expandedInitiatives={expandedInitiatives}
            boundary={boundary}
            windowStart={windowStart}
            isSubInitiative={true}
          />
        ))}
    </div>
  );
};
