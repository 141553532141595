import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DurationStatus } from '../../../../../generated/graphql';

type Initiative = {
  name: string;
  id: string;
  domainId: { itemId: string; tenantId: string };
  tag: {
    title: string;
    iconId: string;
    colorCode: string;
  };
  metadata: {
    completedAt: string | null;
    archived: boolean;
    status: DurationStatus;
  };
};

interface Props extends SelectProps {
  initiatives: Initiative[];
  onChange?: (initiative: Initiative) => void;
}

export const InitiativeSelect = ({
  onChange,
  initiatives,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const options = [
    {
      label: <span>{t('InitiativeSelect.activeInitiatives')}</span>,
      options: initiatives
        .filter((i) => i.metadata.status === DurationStatus.ACTIVE)
        .map((i) => ({
          value: i.domainId.itemId,
          label: i.name,
        })),
    },
    {
      label: <span>{t('InitiativeSelect.upcomingInitiatives')}</span>,
      options: initiatives
        .filter((i) => i.metadata.status === DurationStatus.UPCOMING)
        .map((i) => ({
          value: i.domainId.itemId,
          label: i.name,
        })),
    },
  ];

  return (
    <Select
      {...props}
      onChange={(itemId) => {
        const initiative = initiatives.find(
          (i) => i.domainId.itemId === itemId
        );
        if (initiative) {
          onChange?.(initiative);
        }
      }}
      options={options}
    />
  );
};
