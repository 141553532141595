import './MilestoneActivitiesTable.less';
import { GetSprintKeyActivitiesForMilestoneDocument } from '../../../../../../../../generated/graphql';
import { MilestoneActivityTableRow } from './MilestoneActivityTableRow';
import { useTranslation } from 'react-i18next';
import { gql, useSuspenseQuery } from '@apollo/client';
import { stringSort } from '../../../../../../../../services/stringSort';
import { MilestoneActivitiesTableSkeleton } from './MilestoneActivitiesTable.skeleton';
import { Empty } from 'antd';

interface Props {
  milestoneItemId: string;
}

export const MilestoneActivitiesTable = ({ milestoneItemId }: Props) => {
  const { data } = useSuspenseQuery(
    GetSprintKeyActivitiesForMilestoneDocument,
    { variables: { milestoneId: milestoneItemId } }
  );
  const { t } = useTranslation();

  const sortedKa =
    data.sprintKeyActivitiesForMilestone.sprintKeyActivities.sort((a, b) =>
      stringSort(a.deadline, b.deadline)
    );

  return (
    <div className="MilestoneActivitiesTable">
      <table className="MilestoneActivitiesTable">
        <thead>
          <tr>
            <th className="text-l">{t('common.deadline')}</th>
            <th className="text-l">{t('common.activity')}</th>
            <th className="text-l">{t('common.team')}</th>
            <th className="text-c">{t('common.status')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedKa.map((ska) => (
            <MilestoneActivityTableRow key={ska.id} keyActivity={ska} />
          ))}
          {sortedKa.length === 0 && (
            <tr className="text-c" style={{ height: 120 }}>
              <td colSpan={5}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  className="mt--xxl"
                  description={t('MilestoneActivitiesTable.noData')}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

MilestoneActivitiesTable.Skeleton = MilestoneActivitiesTableSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITIES_FOR_MILESTONES = gql`
  query getSprintKeyActivitiesForMilestone($tenantId: ID, $milestoneId: ID!) {
    sprintKeyActivitiesForMilestone(
      tenantId: $tenantId
      milestoneId: $milestoneId
    ) {
      sprintKeyActivities {
        id
        ...MilestoneActivityTableRow__Mitem
      }
    }
  }
`;
