import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { ArchiveOutlinedIcon } from '../../../../../../../../../icons/ArchiveIcon';
import { CheckIcon } from '../../../../../../../../../icons/overviewIcons/CheckIcon';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../../../../components/Button';
import { SearchOutlineIcon } from '../../../../../../../../../icons/initiativeIcons/SearchOutlineIcon';
import { MilestoneModalState } from '../../hooks/useMilestoneModal';
import { TeamOutlineIcon } from '../../../../../../../../../icons/initiativeIcons/TeamOutlineIcon';
import { useTenantPermissions } from '../../../../../../../../../usePermissions';
import {
  Action,
  TeamResourceType,
} from '../../../../../../../../../generated/graphql';
import { usePermissionForTeams } from '../../../../../../../../../hooks/usePermissionForTeams';
import { atLeastOne } from '../../../../../../../../../services/error-parser/helpers';

type MenuItem = Required<MenuProps>['items'][number] & { include?: boolean };

interface Props {
  milestoneId: string;
  milestoneName: string;
  openModal: (modalState: MilestoneModalState) => void;
}

export const MilestoneActions = ({
  milestoneId,
  milestoneName,
  openModal,
}: Props) => {
  const { t } = useTranslation();

  const { permissionsTenant } = useTenantPermissions();
  const { data } = usePermissionForTeams(
    TeamResourceType.TEAM_LINKS,
    Action.UPDATE
  );

  const allowedToUpdateMilestone = permissionsTenant?.milestone.some(
    (permittedAction) => permittedAction === Action.UPDATE
  );

  const allowedToUpdateTeamLinks = atLeastOne(
    data?.permittedToPerformActionForTeam.permittedResources
  );

  const items: MenuItem[] = [
    {
      key: 'ShowDetails',
      label: <div className="txt--action-blue">{t('common.showDetails')}</div>,
      icon: <SearchOutlineIcon className="txt--action-blue font-size--lg" />,
      onClick: () => openModal({ milestoneId, type: 'details' }),
    },
    {
      label: <div className="txt--action-blue">{t('common.edit')}</div>,
      key: 'EditMilestone',
      icon: <EditOutlined className="txt--action-blue font-size--lg" />,
      onClick: () => openModal({ milestoneId, type: 'edit' }),
      include: allowedToUpdateMilestone,
    },
    {
      label: (
        <div className="txt--action-blue">
          {t('MilestoneActions.manageTeams')}
        </div>
      ),
      key: 'ManageTeams',
      icon: <TeamOutlineIcon className="txt--action-blue font-size--lg" />,
      onClick: () =>
        openModal({ milestoneId, milestoneName, type: 'manageTeams' }),
      include: allowedToUpdateTeamLinks,
    },
    {
      label: (
        <div className="txt--action-blue">
          {t('common.archive')} (not implemented)
        </div>
      ),
      key: 'ArchiveMilestone',
      icon: <ArchiveOutlinedIcon className="txt--action-blue font-size--lg" />,
      include: allowedToUpdateMilestone,
    },
    {
      label: (
        <div className="txt--action-blue">
          {t('MilestoneActions.markedAsCompleted')} (not implemented)
        </div>
      ),
      key: 'MarkAsComplete',
      icon: <CheckIcon className="txt--action-blue font-size--lg" />,
      include: allowedToUpdateMilestone,
    },
  ];

  const filteredItems = items.filter(
    (item) => 'include' in item === false || item.include === true
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown menu={{ items: filteredItems }}>
        <Btn type="text" icon={<MoreOutlined />} />
      </Dropdown>
    </div>
  );
};
