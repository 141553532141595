import dayjs, { Dayjs } from 'dayjs';
import cx from 'classnames';
import './MonthSlices.less';
import { atLeastOne } from '../../../../../services/error-parser/helpers';
import { MilestoneIndicator } from './MonthSlices/MilestoneIndicator';
import { InitiativeDetailsTreeNode } from '../../../../../appPages/company/initiatives/initiativeDetails/InitiativeDetails';

interface Props {
  startOfMonth: Dayjs;
  initiativeNode: InitiativeDetailsTreeNode;
  window: { start: Dayjs; end: Dayjs };
  showAggregated?: boolean;
}

const NUM_SLICES = 4;

export const MonthSlices = ({
  startOfMonth,
  initiativeNode,
  window,
  showAggregated,
}: Props) => {
  const daysInMonth = startOfMonth.daysInMonth();
  const daysInSlice = Math.floor(daysInMonth / NUM_SLICES);

  const monthSlices = Array.from({ length: NUM_SLICES }).map((_, i) => {
    const start = startOfMonth.add(i * daysInSlice, 'days');
    let end = startOfMonth.add((i + 1) * daysInSlice - 1, 'days');

    if (i === NUM_SLICES - 1) {
      end = startOfMonth.endOf('month');
    }

    return { start, end };
  });

  const initStart = isNonEmptyString(
    initiativeNode.data.gantishInitiative.startAt
  )
    ? dayjs(initiativeNode.data.gantishInitiative.startAt)
    : null;

  const initEnd = isNonEmptyString(initiativeNode.data.gantishInitiative.endAt)
    ? dayjs(initiativeNode.data.gantishInitiative.endAt)
    : null;

  const monthSlicesWithInitInfo = monthSlices.map((slice) => {
    const startsBeforeWindow =
      (initStart == null || initStart.isBefore(window.start)) &&
      slice.start.isSame(window.start) &&
      (initEnd == null || initEnd.isAfter(window.start));

    const endsAfterWindow =
      (initEnd == null || initEnd.isAfter(window.end)) &&
      slice.end.isSame(window.end) &&
      (initStart == null || initStart.isBefore(window.end));

    const isStarting =
      initStart != null &&
      initStart.isSameOrAfter(slice.start) &&
      initStart.isSameOrBefore(slice.end);

    const isEnding =
      initEnd != null &&
      initEnd.isSameOrAfter(slice.start) &&
      initEnd.isSameOrBefore(slice.end);

    const hasStarted =
      initStart == null || initStart.isSameOrBefore(slice.start);

    const hasEnded = initEnd != null && initEnd.isBefore(slice.start);

    const isOngoing = !isStarting && !isEnding && hasStarted && !hasEnded;

    const milestones = (
      showAggregated
        ? initiativeNode.data.accumulatedMilestones.milestones
        : initiativeNode.data.milestones
    ).filter((m) => {
      const milestoneDate = dayjs(m.milestone.deadlineAt);
      return milestoneDate.isBetween(slice.start, slice.end, 'day', '[]');
    });

    return {
      ...slice,
      isStarting,
      isEnding,
      isOngoing,
      startsBeforeWindow,
      endsAfterWindow,
      milestones,
    };
  });

  return (
    <>
      {monthSlicesWithInitInfo.map((slice, i) => (
        <div
          className={cx('MonthSlices__slice', {
            'MonthSlices__slice--starting': slice.isStarting,
            'MonthSlices__slice--ending': slice.isEnding,
            'MonthSlices__slice--ongoing': slice.isOngoing,
            'MonthSlices__slice--startsBeforeWindow': slice.startsBeforeWindow,
            'MonthSlices__slice--endsAfterWindow': slice.endsAfterWindow,
          })}
          style={{
            ['--num-slices' as string]: monthSlices.length,
          }}
          key={i}
        >
          {atLeastOne(slice.milestones) && (
            <MilestoneIndicator milestones={slice.milestones} />
          )}
          {slice.startsBeforeWindow && <Fnutt />}
          {slice.endsAfterWindow && <Fnutt isEnd />}
        </div>
      ))}
    </>
  );
};

const isNonEmptyString = (s: string | undefined | null): s is string =>
  s != null && s !== '';

const Fnutt = (props: { isEnd?: boolean }) => {
  const strokeWidth = 2;

  return (
    <svg
      height={`calc(100% + ${strokeWidth * 2}px)`}
      style={{
        position: 'absolute',
        [props.isEnd ? 'right' : 'left']: -11,
        top: -strokeWidth,
        transform: `scaleX(${props.isEnd ? -1 : 1})`,
      }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 25"
    >
      <path
        className="MonthSlices__fnuttBg"
        d="M1 24h10V0H1l2.038 4.835a3 3 0 0 1 0 2.33l-1.502 3.563a3 3 0 0 0 .081 2.507l1.712 3.423a3 3 0 0 1 0 2.684L1 24Z"
      />
      <path
        className="MonthSlices__fnuttStroke"
        d="M11 .5H1l2.038 4.835a3 3 0 0 1 0 2.33l-1.502 3.563a3 3 0 0 0 .081 2.507l1.712 3.423a3 3 0 0 1 0 2.684L1 24.5h10"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
