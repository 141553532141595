import cx from 'classnames';
import dayjs from 'dayjs';
import { Moment } from 'moment';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  MitemPlaningBoard_MilestoneFragment,
  MitemPlaningBoard_MitemFragment,
} from '../../../../../generated/graphql';
import { standardDateFormat } from '../../../../../services/dateFormats';
import { momentToDayjs } from '../../../../../services/dateHelpers';
import { groupBy } from '../../../../../services/groupBy';
import { Sprint } from '../../../common/hooks/useSprintPlanningData';
import { MitemListEntry } from './MitemListEntry';
import './MitemPlanningBoard.less';
import { MitemBoardColumn } from './mitemPlanningBoard/MitemBoardColumn';
import {
  SprintWeekCard,
  WeekCardTitle,
} from './mitemPlanningBoard/mitemBoardColumn/SprintWeekCard';
import { gql } from '@apollo/client';
import { MilestoneCard } from './mitemPlanningBoard/milestoneCard/MilestoneCard';

interface Props {
  sprints: Sprint[];
  sprintPeriodMap: Record<
    number,
    { timePeriodStartDate: Moment; timePeriodEndDate: Moment }[]
  >;
  mitems?: MitemPlaningBoard_MitemFragment[];
  milestones?: MitemPlaningBoard_MilestoneFragment[];
  teamId: string;
  onOpenCreateForm: (deadline: string) => void;
}

export const MitemPlanningBoard = ({
  mitems,
  sprints,
  sprintPeriodMap,
  onOpenCreateForm,
  milestones,
  teamId,
}: Props) => {
  const [highlightedMitem, setHighlightedMitem] = useState<string | null>(null);
  const mitemsPerPeriod = mitems && groupBy(mitems, (m) => m.deadline);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="MitemPlanningBoard">
        <div className="MitemPlanningBoard__columnsWrapper">
          {sprints.map((sprint, columnIndex) => {
            const periods = sprintPeriodMap?.[sprint.sprintNumber];
            const hasMitems =
              mitems?.some(
                (m) =>
                  dayjs(m.deadline).isSameOrAfter(
                    momentToDayjs(sprint.startDate)
                  ) &&
                  dayjs(m.deadline).isSameOrBefore(
                    momentToDayjs(sprint.endDate)
                  )
              ) ?? false;

            return (
              <MitemBoardColumn
                key={sprint.sprintNumber}
                sprint={sprint}
                columnIndex={columnIndex}
                hasMitems={hasMitems}
              >
                {periods?.map((period) => {
                  const periodEndDate = period.timePeriodEndDate;

                  const mitemsThisPeriod =
                    mitemsPerPeriod?.[standardDateFormat(periodEndDate)];

                  const milestonesInPeriod =
                    milestones?.filter(
                      (m) =>
                        dayjs(m.deadlineAt).isSameOrAfter(
                          momentToDayjs(period.timePeriodStartDate)
                        ) &&
                        dayjs(m.deadlineAt).isSameOrBefore(
                          momentToDayjs(period.timePeriodEndDate)
                        )
                    ) ?? [];

                  return (
                    <SprintWeekCard
                      key={period.timePeriodStartDate.format()}
                      teamId={teamId}
                      title={
                        <WeekCardTitle
                          startDate={period.timePeriodStartDate}
                          endDate={periodEndDate}
                        />
                      }
                      sprint={sprint}
                      onOpenCreateForm={() =>
                        onOpenCreateForm(standardDateFormat(periodEndDate))
                      }
                      endDate={periodEndDate}
                      onPendingConfirm={setHighlightedMitem}
                    >
                      {milestonesInPeriod.map((m) => (
                        <MilestoneCard key={m.id} milestone={m} />
                      ))}
                      <div>
                        {mitemsThisPeriod?.map((m) => (
                          <MitemListEntry
                            key={m.id}
                            teamId={teamId}
                            sprintKeyActivity={m}
                            sprint={sprint}
                            highlighted={m.id === highlightedMitem}
                          />
                        ))}
                      </div>
                    </SprintWeekCard>
                  );
                })}
              </MitemBoardColumn>
            );
          })}
        </div>
      </div>
      <div
        className={cx('SprintPlanningPage__mask', {
          'SprintPlanningPage__mask--visible': highlightedMitem,
        })}
      ></div>
    </DndProvider>
  );
};

export const ItemTypes = {
  MITEM: 'mitem',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MITEM_PLANING_BOARD_QUERY = gql`
  fragment MitemPlaningBoard_Mitem on Mitem {
    id
    deadline
    ...MitemListEntry_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MITEM_PLANING_BOARD__MILESTONE = gql`
  fragment MitemPlaningBoard_Milestone on Milestone {
    id
    deadlineAt
    name
    ...MilestoneCard_Milestone
  }
`;
