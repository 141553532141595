import { DatePicker, DatePickerProps } from 'antd';
import './SprintDatepicker.less';
import { AccelerationDay } from '../../../../../generated/graphql';
import { DateStatus, getSprintDateStatus } from './getSprintDateStatus';
import { friendlyDate } from '../../../../../services/dateFormats';
import { isValidDeadline } from '../../common/services/isValidDeadline';
import { useCurrentSprint } from '../../../../../hooks/useCurrentSprint';
import { useSprintTerms } from '../../../../../hooks/useSprintTerms';
import { dayjsToMoment } from '../../../../../services/dateHelpers';
import dayjs, { Dayjs } from 'dayjs';

type Props = DatePickerProps<Dayjs> & {
  teamId: string;
  accelerationDay?: AccelerationDay;
};

export const SprintDatepicker = ({
  teamId,
  accelerationDay,
  ...rest
}: Props) => {
  const { currentSprint, loading } = useCurrentSprint(teamId);
  const { data, loading: loadingTerm } = useSprintTerms(teamId);

  const terms = data ?? [];
  return (
    <DatePicker
      key={teamId}
      disabled={loadingTerm || loading}
      data-testid="sprint-datepicker"
      format={(date) => friendlyDate(date)}
      allowClear={false}
      disabledDate={(date) =>
        accelerationDay == null ||
        (terms.length > 0 &&
          !isValidDeadline({
            date,
            accelerationDay,
            currentSprint,
            terms,
          }))
      }
      cellRender={(currentDate, info) => {
        const status =
          accelerationDay &&
          terms.length > 0 &&
          typeof currentDate !== 'number' &&
          typeof currentDate !== 'string' &&
          getSprintDateStatus({
            selectedDate: dayjsToMoment(currentDate),
            accelerationDay,
            currentSprint,
            terms,
          });

        let className;

        switch (status) {
          case DateStatus.ACTIVE:
            className = 'SprintDatepicker__date--active';
            break;
          case DateStatus.PLANNED:
            className = 'SprintDatepicker__date--planned';
            break;
          case DateStatus.DEFAULT:
          default:
            className = 'SprintDatepicker__date';
            break;
        }

        if (info.type === 'year') {
          return (
            <div className={className}>
              {dayjs.isDayjs(currentDate) ? currentDate.year() : currentDate}
            </div>
          );
        }
        if (info.type === 'month') {
          return (
            <div className={className}>
              {dayjs.isDayjs(currentDate)
                ? currentDate.format('MMM')
                : currentDate}
            </div>
          );
        }

        return (
          <div className={className}>
            {dayjs.isDayjs(currentDate) ? currentDate.date() : currentDate}
          </div>
        );
      }}
      {...rest}
    />
  );
};
