import { Form } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useSkaValidationRules } from '../../../../common/utils/useSkaValidationRules';

import { useTranslation } from 'react-i18next';
import { atLeastOne } from '../../../../../../services/error-parser/helpers';
import { gql, useQuery } from '@apollo/client';
import { MitemFormTenantInitiativesForTeamDocument } from '../../../../../../generated/graphql';
import { TenantInitiativeSelector } from './TenantInitiativeSelector';

interface Props {
  selectedMigId: string | null;
  teamId: string;
  selectedInitiativeIds: string[];
  initialSupportInitiativeIds?: string[];
}

export const MitemFormTenantInitiativeFormItem = ({
  teamId,
  selectedMigId,
  selectedInitiativeIds,
  initialSupportInitiativeIds,
}: Props) => {
  const rulesFor = useSkaValidationRules();
  const { t } = useTranslation();
  const { data } = useQuery(MitemFormTenantInitiativesForTeamDocument, {
    variables: { teamId },
  });

  const tenantInitiatives =
    data?.joinedTenantInitiativesForTeam.initiatives ?? [];

  const selectableTeamInitiatives = tenantInitiatives.filter(
    (ti) =>
      (ti.metadata.archived === false && ti.metadata.completedAt == null) ||
      initialSupportInitiativeIds?.some((id) => id === ti.id)
  );

  const teamHasInitiatives = atLeastOne(tenantInitiatives);

  // TODO: REMOVE THIS VALIDATION LOGIC?
  const initiativeRequired = !selectedMigId && teamHasInitiatives;

  return (
    <Form.Item label={t('MitemForm.initiative')} required={initiativeRequired}>
      <Form.List name="supportsInitiativeIds">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <div className="flx" key={field.key + index}>
                  <Form.Item
                    className="flx--1"
                    {...field}
                    rules={rulesFor.supportsInitiativeIds({
                      initiativeRequired,
                    })}
                  >
                    <TenantInitiativeSelector
                      initiatives={selectableTeamInitiatives ?? []}
                      selectedInitiativeIds={selectedInitiativeIds}
                      className={'SprintKaForm__initiativeSelector'}
                      disabled={!teamHasInitiatives}
                      placeHolder={
                        !teamHasInitiatives
                          ? t('MitemForm.noInitiativesAvailable')
                          : undefined
                      }
                    />
                  </Form.Item>
                  <Btn
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                </div>
              ))}
              <Form.Item>
                <Btn
                  type="link"
                  style={{ padding: 0 }}
                  icon={<PlusOutlined />}
                  disabled={
                    !teamHasInitiatives ||
                    tenantInitiatives?.length === selectedInitiativeIds?.length
                  }
                  onClick={() => {
                    add();
                  }}
                >
                  {t('MitemForm.addInitiative')}
                </Btn>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_INITIATIVES_FOR_TEAM = gql`
  query mitemFormTenantInitiativesForTeam($tenantId: ID, $teamId: ID!) {
    joinedTenantInitiativesForTeam(tenantId: $tenantId, teamId: $teamId) {
      initiatives {
        id
        name
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          status
          archived
        }
      }
    }
  }
`;
