import React from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import './ScrollableCard.less';

interface Props extends CardProps {}

export const ScrollableCard: React.FC<Props> = ({
  children,
  className,
  bodyStyle,
  ...rest
}) => {
  const classes = 'ScrollableCard ' + (className ?? '');
  const bodyStyles = { overflow: 'auto', maxHeight: '100%', ...bodyStyle };

  return (
    <Card className={classes} bodyStyle={bodyStyles} {...rest}>
      {children}
    </Card>
  );
};
