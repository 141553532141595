import { LockFilled, UnlockOutlined } from '@ant-design/icons';
import { Alert, Card, Progress, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { DisplayDateRange } from '../../../../components/DisplayDateRange';
import {
  Action,
  MitemSprintViewQueryDocument,
  MitemStatus,
  SprintPerformanceQueryDocument,
} from '../../../../generated/graphql';
import { useTeamSprints } from '../../../../hooks/useTeamSprints';
import { CreateMitemModal } from '../common/hooks/useCreateMitemModal';
import { useMitemFilters } from '../common/hooks/useMitemFilters';
import './SprintSummaryPage.less';
import { FilterHeader } from './components/FilterHeader';
import { SprintDetails } from './components/SprintDetails';
import { SprintMitemTable } from './components/SprintMitemTable';
import { SprintMitemTableActions } from './components/SprintMitemTableActions';
import { SprintProgress } from './components/SprintProgress';
import { CreateMitemDrawer } from '../planning/components/CreateMitemDrawer';
import { LearnMoreLink } from '../../../../components/LearnMoreLink';
import { TourLink } from '../../../../components/TourLink';
import { Colors } from '../../../componentLibrary/Colors';
import { isPossibleToChangeArchiveState } from '../common/services/useMitemArchivation';
import { Btn } from '../../../../components/Button';
import { useTeamPermissions } from '../../../../usePermissions';
import { PermissionChecker } from '../../../../PermissionChecker';
import dayjs from 'dayjs';
import { gql, useLazyQuery } from '@apollo/client';
import { useAiSuggestFeatureFlag } from '../../../../hooks/featureFlagHooks';
import { AiSuggestButton } from '../common/components/aiSuggest/AiSuggestButton';
import { useTenantDetails } from '../../../../hooks/useTenantDetails';

interface Props {
  teamId: string;
  createMitemModal: CreateMitemModal;
}

export const SprintSummaryPage = ({ teamId, createMitemModal }: Props) => {
  const { t } = useTranslation();
  const aiSuggestEnabled = useAiSuggestFeatureFlag();
  const { features } = useTenantDetails();

  const {
    selectedSprintIndex,
    setSelectedSprintIndex,
    currentSprintIndex,
    sprints,
    errorFetchingSprintData,
    loadingSprintData: LoadingSprintData,
  } = useTeamSprints(teamId);

  const { isAllowed: allowedToEdit } = useTeamPermissions({
    requestedAction: {
      resource: 'sprintKA',
      action: Action.UPDATE,
    },
    teamId: teamId,
  });

  const { currentFilters, setFilter } = useMitemFilters();
  const [SKAsCompletedRatio, setSKAsCompletedRatio] = useState<number>(0);
  const currentSprint = sprints[currentSprintIndex];

  const [
    loadMitems,
    {
      data: mitemData,
      error: errorFetchingMitemData,
      loading: loadingMitemData,
    },
  ] = useLazyQuery(MitemSprintViewQueryDocument, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const allSKAs = mitemData?.skaTimeStatuses.sprintKaStatuses.length;
    const completedSKAs = mitemData?.skaTimeStatuses.sprintKaStatuses.filter(
      (ska) => ska.status === 'COMPLETED'
    );
    const completedSkasNr = completedSKAs?.length || 0;
    allSKAs &&
      completedSKAs &&
      setSKAsCompletedRatio(
        parseInt(((completedSkasNr / allSKAs) * 100).toFixed(1))
      );
  }, [mitemData]);

  const firstSprint = sprints[0];
  const lastSprint = sprints[sprints.length - 1];
  const selectedSprint = sprints[selectedSprintIndex];
  const currentSprintNumber = selectedSprintIndex + 1;
  const showTerm = selectedSprint == null && !!firstSprint && !!lastSprint;
  const timeStatuses = mitemData?.skaTimeStatuses.sprintKaStatuses ?? [];

  const hasSprints = sprints.length > 0;

  const filteredStartDate = hasSprints
    ? (selectedSprint?.startDate ?? firstSprint.startDate)
    : null;
  const filteredEndDate = hasSprints
    ? (selectedSprint?.endDate ?? lastSprint.endDate)
    : null;

  useEffect(() => {
    if (filteredStartDate && filteredEndDate) {
      loadMitems({
        variables: {
          teamId: teamId,
          startDate: filteredStartDate,
          endDate: filteredEndDate,
        },
      });
    }
  }, [loadMitems, teamId, filteredStartDate, filteredEndDate, features]);

  const handleCreateMitem = () => {
    if (filteredStartDate && filteredEndDate) {
      loadMitems({
        variables: {
          teamId: teamId,
          startDate: filteredStartDate,
          endDate: filteredEndDate,
        },
      });
    }
  };

  return (
    <div className="flx flx--column">
      <Helmet title={t('SprintSummaryPage.sprintView')} />
      <div className="flx flx--ai-center mb--xl mt--l">
        <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28919-key-activities-in-sprint" />
        <TourLink
          intercomTarget="Sprint view product tour button"
          engTourId="2323"
          sweTourId="2309"
        />
      </div>

      <div className="flx flx--ai-center mb--l">
        <FilterHeader
          timeStatuses={timeStatuses}
          currentFilters={currentFilters}
          setFilter={setFilter}
          sprintInfo={{
            sprints,
            selectedSprintIndex,
            currentSprintIndex,
            setSelectedSprintIndex,
          }}
          teamId={teamId}
        />
        <PermissionChecker
          resourceOwner={{
            type: 'TeamResource',
            teamId: teamId,
            requestedAction: {
              resource: 'sprintKA',
              action: Action.CREATE,
            },
          }}
        >
          <Btn
            type="primary"
            className="ml--auto"
            onClick={() => {
              createMitemModal.openModal();
            }}
            data-intecom-target="Plan View Create Sprint Key Activity Button"
          >
            {t('SprintSummaryPage.createMItem')}
          </Btn>
          {aiSuggestEnabled && <AiSuggestButton teamId={teamId} />}
        </PermissionChecker>
      </div>
      <div className="flx">
        <div
          className="flx--1 mr"
          data-intercom-target="Sprint View Table Area"
        >
          <Card
            title={
              <div className="flx flx--jc-space-between">
                <div className="flx flx--ai-center">
                  <div
                    className="mr"
                    data-intercom-target="Sprint View Table Sprint Locked Indicator"
                  >
                    {selectedSprint?.locked ? (
                      <Tooltip title={t('SprintSummaryPage.Locked')}>
                        <LockFilled
                          style={{
                            color: `${
                              selectedSprint?.locked
                                ? '#FF6309'
                                : Colors.Status.FUTURE_PURPLE
                            }`,
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('SprintSummaryPage.Unlocked')}>
                        <UnlockOutlined
                          style={{
                            color: `${
                              selectedSprint?.locked
                                ? '#FF6309'
                                : Colors.Status.FUTURE_PURPLE
                            }`,
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div
                    className="mr"
                    data-intercom-target="Sprint View Table Sprint Number Indicator"
                    style={{
                      color: `${
                        selectedSprint?.locked
                          ? '#FF6309'
                          : Colors.Status.FUTURE_PURPLE
                      }`,
                    }}
                  >
                    {showTerm
                      ? t('SprintSummaryPage.allSprintsTitle')
                      : t('SprintSummaryPage.Sprint', {
                          sprintNumber: currentSprintNumber,
                        })}
                  </div>

                  <Typography.Text type="secondary">
                    {showTerm && (
                      <DisplayDateRange
                        startDate={firstSprint.startDate}
                        endDate={lastSprint.endDate}
                      />
                    )}
                    {selectedSprint && (
                      <DisplayDateRange
                        startDate={filteredStartDate}
                        endDate={filteredEndDate}
                      />
                    )}
                  </Typography.Text>
                </div>
                <div className="flx">
                  <Progress
                    percent={SKAsCompletedRatio}
                    size="small"
                    style={{ width: 150 }}
                  />
                  <small className="ml--s">{t('MitemHistory.completed')}</small>
                </div>
              </div>
            }
            bodyStyle={{ padding: 0 }}
          >
            {!!errorFetchingSprintData ||
              (!!errorFetchingMitemData && (
                <Alert type="error" message={t('SprintMitemTableCard.error')} />
              ))}
            <SprintMitemTable
              timeStatuses={timeStatuses}
              loading={LoadingSprintData || loadingMitemData}
              filters={currentFilters}
              actionColumn={
                allowedToEdit
                  ? {
                      title: t('common.actions'),
                      dataIndex: 'actions',
                      key: 'actions',
                      render: (_, timeStatus) => {
                        if (!timeStatus.sprintKeyActivity) return null;
                        return (
                          <SprintMitemTableActions
                            teamId={teamId}
                            mitem={timeStatus.sprintKeyActivity}
                            isLockedSprint={selectedSprint?.locked ?? false}
                            isAllowedToArchive={
                              selectedSprint
                                ? isPossibleToChangeArchiveState(
                                    timeStatus.sprintKeyActivity,
                                    selectedSprint
                                  )
                                : dayjs(timeStatus.deadline).isAfter(
                                    dayjs(currentSprint.endDate),
                                    'day'
                                  )
                            }
                            editable={
                              !(
                                timeStatus.status ===
                                  MitemStatus.NOT_COMPLETED ||
                                timeStatus.status === MitemStatus.ARCHIVED
                              )
                            }
                            refetchQueries={[
                              {
                                query: SprintPerformanceQueryDocument,
                                variables: {
                                  teamId: teamId,
                                  startDate: filteredStartDate,
                                  endDate: filteredEndDate,
                                  includeSka: true,
                                },
                              },
                            ]}
                          />
                        );
                      },
                    }
                  : undefined
              }
            />
          </Card>
        </div>

        <div className="SprintSummaryPage__sideArea">
          <Card
            title={
              showTerm
                ? t('SprintSummaryPage.allSprintsProgressTitle')
                : t('SprintSummaryPage.sprintProgressTitle')
            }
            className="mb--s"
            data-intercom-target="Sprint View Sprint Progress Area"
          >
            {errorFetchingSprintData && (
              <Alert type="error" message={t('SprintProgress.Error')} />
            )}

            <SprintProgress
              total={timeStatuses.length}
              completed={
                timeStatuses.filter(
                  (tsm) => tsm.status === MitemStatus.COMPLETED
                ).length
              }
            />
          </Card>

          {!showTerm && (
            <Card
              title={t('SprintSummaryPage.sprintDetailsTitle')}
              data-intercom-target="Sprint View Sprint Details Area"
            >
              {errorFetchingSprintData && (
                <Alert type="error" message={t('SprintDetails.Error')} />
              )}

              <SprintDetails sprint={selectedSprint} />
            </Card>
          )}
        </div>
      </div>
      <CreateMitemDrawer
        teamId={teamId}
        showModal={createMitemModal.isOpen}
        onCancel={createMitemModal.closeModal}
        prefilledDeadline={createMitemModal.periodToCreateMitemIn}
        onCompleted={handleCreateMitem}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_SPRINT_VIEW_QUERY = gql`
  query mitemSprintViewQuery(
    $teamId: ID!
    $startDate: String!
    $endDate: String!
  ) {
    skaTimeStatuses(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      sprintKaStatuses {
        id
        teamId
        tenantId
        name
        deadline
        status
        owner {
          id
          email
          displayName
          name
        }
        ...FilterHeader_SkaTimeStatus
        ...SprintMitemTable_SkaTimeStatus
        sprintKeyActivity {
          ...MitemSprintView_Mitem
        }
      }
    }
  }
`;

export const MITEM_SPRINT_VIEW = gql`
  fragment MitemSprintView_Mitem on Mitem {
    id
    status
    ...SprintMitemTableActions_Mitem
  }
`;
