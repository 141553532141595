import { PlusOutlined, WarningFilled } from '@ant-design/icons';
import {
  PureQueryOptions,
  RefetchQueriesFunction,
  gql,
  useLazyQuery,
} from '@apollo/client';
import { Empty, Spin, Tooltip, Typography } from 'antd';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayName } from '../../../../../../../../../components/DisplayName';
import { useTeamMembers } from '../../../../../../../../../hooks/useTeamMembers';
import {
  friendlyDate,
  standardDateFormat,
} from '../../../../../../../../../services/dateFormats';
import { Sprint } from '../../../../../../../common/hooks/useSprintPlanningData';
import { AddPlannedKeyActivityToSprintModal } from './AddPlannedKeyActivityToSprintModal';
import './PlannedKeyActivityCard.less';
import cx from 'classnames';
import {
  GetMitemsAddExistingDrawerDocument,
  MitemStatus,
  PlannedKeyActivityCardWithModal_MitemFragment,
  PlannedKeyActivityCard_MitemFragment,
} from '../../../../../../../../../generated/graphql';
import { Btn } from '../../../../../../../../../components/Button';
import { stringSort } from '../../../../../../../../../services/stringSort';

interface PlannedKeyActivitiesProps {
  teamId: string;
  sprints: Sprint[];
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
  futurePeriodsInSprint: {
    timePeriodStartDate: Moment;
    timePeriodEndDate: Moment;
  }[];
}

export const PlannedKeyActivities = ({
  teamId,
  sprints,
  futurePeriodsInSprint,
  refetchQueries,
}: PlannedKeyActivitiesProps) => {
  const { t } = useTranslation();
  const [currentSprint, ...futureSprints] = sprints;

  const firstSprint = futureSprints[0];
  const lastSprint = futureSprints[futureSprints.length - 1];

  const { isMember, loading: loadingMembers } = useTeamMembers(teamId);
  const [fetchMitems, { data, loading }] = useLazyQuery(
    GetMitemsAddExistingDrawerDocument,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const mitems = [
    ...(data?.sprintKeyActivities.sprintKeyActivities ?? []),
  ].sort((a, b) => stringSort(a.deadline, b.deadline));

  const startDate =
    firstSprint?.startDate && standardDateFormat(firstSprint.startDate);
  const endDate = lastSprint?.endDate && standardDateFormat(lastSprint.endDate);

  useEffect(() => {
    if (startDate && endDate && teamId) {
      fetchMitems({
        variables: {
          teamId,
          startDate,
          endDate,
          archived: false,
        },
      });
    }
  }, [fetchMitems, teamId, startDate, endDate]);

  if (loading || loadingMembers)
    return (
      <Spin className="center-content" delay={300}>
        <Empty
          description={t('PlannedKeyActivities.noActivities')}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </Spin>
    );

  const plannedKeyActivities = mitems?.filter(
    (m) => m.status === MitemStatus.PLANNED
  );

  if (plannedKeyActivities == null || plannedKeyActivities.length === 0)
    return (
      <Empty
        description={t('PlannedKeyActivities.noActivities')}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );

  return (
    <div>
      {plannedKeyActivities.map((m) => (
        <PlannedKeyActivityCardWithModal
          key={m.id}
          mitem={m}
          currentSprintId={currentSprint.id}
          refetchQueries={refetchQueries}
          isMember={isMember}
          futurePeriodsInSprint={futurePeriodsInSprint}
        />
      ))}
    </div>
  );
};

interface PlannedKeyActivityCardWithModalProps {
  mitem: PlannedKeyActivityCardWithModal_MitemFragment;
  isMember: (userId: string) => boolean | null;
  currentSprintId: string | null;
  futurePeriodsInSprint: {
    timePeriodStartDate: Moment;
    timePeriodEndDate: Moment;
  }[];
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
}

const PlannedKeyActivityCardWithModal = ({
  mitem,
  currentSprintId,
  futurePeriodsInSprint,
  refetchQueries,
  isMember,
}: PlannedKeyActivityCardWithModalProps) => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <div>
      <PlannedKeyActivityCard
        mitem={mitem}
        isMember={isMember}
        selected={showEditModal}
        onClick={() => {
          setShowEditModal(true);
        }}
        actions={
          <>
            <Tooltip
              mouseEnterDelay={0.7}
              title={t('PlannedKeyActivityCard.addInfo')}
            >
              <span>
                <Btn
                  className="PlannedKeyActivityCard__addButton"
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<PlusOutlined />}
                />
              </span>
            </Tooltip>
          </>
        }
      />

      <AddPlannedKeyActivityToSprintModal
        mitem={mitem}
        isMember={isMember}
        refetchQueries={refetchQueries}
        showModal={showEditModal}
        currentSprintId={currentSprintId}
        onCompleted={() => setShowEditModal(false)}
        onCancel={() => setShowEditModal(false)}
        selectableDeadlines={futurePeriodsInSprint}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PLANNED_KEY_ACTIVITY_CARD_WITH_MODAL_MITEM = gql`
  fragment PlannedKeyActivityCardWithModal_Mitem on Mitem {
    id
    definitionOfDone
    deadline
    ...AddPlannedKeyActivityToSprintModal_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS = gql`
  query getMitemsAddExistingDrawer(
    $teamId: ID!
    $startDate: String
    $endDate: String
    $archived: Boolean
  ) {
    sprintKeyActivities(
      teamId: $teamId
      archived: $archived
      startDate: $startDate
      endDate: $endDate
    ) {
      sprintKeyActivities {
        status
        ...PlannedKeyActivityCardWithModal_Mitem
      }
    }
  }
`;

interface PlannedKeyActivityCardProps {
  mitem: PlannedKeyActivityCard_MitemFragment;
  className?: string;
  onClick?: () => void;
  isMember: (userId: string) => boolean | null;
  actions?: React.ReactNode;
  fullSize?: boolean;
  selected?: boolean;
}

export const PlannedKeyActivityCard = ({
  mitem,
  isMember,
  className,
  actions,
  onClick,
  selected = false,
  fullSize = false,
}: PlannedKeyActivityCardProps) => {
  const { t } = useTranslation();
  return (
    <div className={className} onClick={onClick}>
      <div
        className={cx(
          'PlannedKeyActivityCard PlannedKeyActivityCard--PLANNED',
          {
            'PlannedKeyActivityCard--selected': selected,
          }
        )}
      >
        <Typography.Text className="PlannedKeyActivityCard__name" strong>
          {mitem.name}
        </Typography.Text>
        <div className="PlannedKeyActivityCard__owner">
          {t('PlannedKeyActivityCard.owner')} <DisplayName user={mitem.owner} />
          <div>{friendlyDate(mitem.deadline)}</div>
          {!isMember(mitem.owner.id) && (
            <WarningFilled className="txt--danger PlannedKeyActivityCard__warning" />
          )}
          {fullSize && (
            <div className="mt--l">
              <Typography.Text strong>
                {t('PlannedKeyActivityCard.dod')}
              </Typography.Text>{' '}
              <div>{mitem.definitionOfDone}</div>
            </div>
          )}
          {actions && (
            <div className="PlannedKeyActivityCard__actions">{actions}</div>
          )}
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PLANNED_KEY_ACTIVITY_CARD_MITEM = gql`
  fragment PlannedKeyActivityCard_Mitem on Mitem {
    id
    definitionOfDone
    deadline
    name
    owner {
      id
      ...DisplayName_User
    }
  }
`;
