import { useMutation } from '@apollo/client';
import { Alert, Card, Modal, Spin, Typography } from 'antd';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MigStatus,
  GetTeamFullMigsV2Document,
  UpdateTeamMigArchivedStatusDocument,
} from '../../../generated/graphql';
import { getStatusByDates } from '../../../services/sprintTermStatus';
import { ErrorAlert } from '../../ErrorAlert';
import { MigCard } from '../../mig/MigCard';
import { MigModalState } from '../MigSetupPage';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { useTeamMigLazyV2 } from './hooks/useTeamMigV2';

interface Props {
  onClose: () => void;
  teamId: string;
  tenantId?: string;
  migModalState: MigModalState | null;
}

export const StatusMigModal = ({
  onClose,
  teamId,
  tenantId,
  migModalState,
}: Props) => {
  const { t } = useTranslation();
  const { fetchTeamMig, teamMig, loading } = useTeamMigLazyV2();
  const isOpen =
    migModalState?.type === 'UpdateMigStatus' && migModalState.id != null;

  useEffect(() => {
    if (migModalState?.type === 'UpdateMigStatus' && migModalState.id) {
      fetchTeamMig({
        variables: {
          teamId,
          tenantId,
          migId: migModalState.id,
        },
      });
    }
  }, [fetchTeamMig, migModalState, teamId, tenantId]);

  const [updateMigStatus, { error: updateError, loading: updateLoading }] =
    useMutation(UpdateTeamMigArchivedStatusDocument, {
      onCompleted: (response) => {
        showNotification('success', {
          message: (
            <strong>
              {response.updateTeamMigArchivedStatus.status ===
              MigStatus.ARCHIVED
                ? t('StatusMigModal.archivedSuccess')
                : t('StatusMigModal.unarchiveSuccess')}
            </strong>
          ),
          description: response.updateTeamMigArchivedStatus.name,
        });

        onClose();
      },
      refetchQueries: () => [
        {
          query: GetTeamFullMigsV2Document,
          variables: { teamId, tenantId },
        },
      ],
    });
  const toBeArchived =
    migModalState?.type === 'UpdateMigStatus' &&
    migModalState.status !== MigStatus.ARCHIVED;

  const handleSave = () => {
    if (!teamMig) {
      throw new Error(
        'MIG id is null or undefined when trying to change MIG status'
      );
    }
    updateMigStatus({
      variables: {
        teamId,
        tenantId,
        migId: teamMig.domainId.itemId,
        rev: teamMig.rev,
        archived: toBeArchived,
      },
    });
  };

  if (migModalState?.type !== 'UpdateMigStatus') return null;

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOk={handleSave}
      keyboard={true}
      confirmLoading={updateLoading}
      okText={
        toBeArchived
          ? t('StatusMigModal.onArchive')
          : t('common.mig.reactivateMIG')
      }
      destroyOnClose
      title={
        <div className="flx flx--column">
          <Typography.Text>
            {toBeArchived
              ? t('StatusMigModal.archiveTitle')
              : t('common.mig.reactivateMIG')}
          </Typography.Text>
        </div>
      }
    >
      <div>
        <ErrorAlert
          error={updateError}
          title={t('StatusMigModal.activationError')}
        />
        {teamMig && (
          <Spin spinning={loading}>
            <Card bodyStyle={{ padding: 0 }}>
              <MigCard
                mig={teamMig}
                showDescription={false}
                graphHeight={120}
              />
            </Card>
          </Spin>
        )}
        {toBeArchived ? (
          <ArchiveMig
            currentStatus={migModalState.status}
            fromActiveStatus={migModalState.status === MigStatus.ACTIVE}
          />
        ) : (
          <ReactivateMig
            startDate={teamMig?.startDate}
            endDate={teamMig?.lastOutcomeDate}
          />
        )}
      </div>
    </Modal>
  );
};

interface StatusProps {
  currentStatus?: MigStatus;
  fromActiveStatus: boolean;
}

const ArchiveMig = ({ currentStatus, fromActiveStatus }: StatusProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt--xl">
      <Alert
        type="warning"
        className="mb"
        message={t('StatusMigModal.archiveWarningTitle')}
        description={
          currentStatus
            ? t('StatusMigModal.currentStatus', {
                status: t(`KeyActivityStatus.${currentStatus}`),
              })
            : ''
        }
      />
      <Typography.Title level={5}>
        {t('StatusMigModal.pleaseNoteTitle')}
      </Typography.Title>
      <ul>
        {fromActiveStatus && <li>{t('StatusMigModal.archiveNoteOne')}</li>}
        <li>{t('StatusMigModal.archiveNoteTwo')}</li>
        <li>{t('StatusMigModal.archiveNoteThree')}</li>
      </ul>
    </div>
  );
};

interface ReactivateProps {
  startDate?: string;
  endDate?: string;
}

const ReactivateMig = ({ startDate, endDate }: ReactivateProps) => {
  const { t } = useTranslation();

  return (
    <div className="mt--xl">
      <Alert
        className="mb--l"
        type="info"
        message={t('StatusMigModal.reactivateTitle')}
      />
      {startDate && endDate && (
        <div>
          <Typography.Title level={5}>
            {t('StatusMigModal.pleaseNoteTitle')}
          </Typography.Title>
          {t(`StatusMigModal.${getStatusByDates(startDate, endDate)}`)}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CHANGE_TEAM_MIG_STATUS = gql`
  mutation updateTeamMigArchivedStatus(
    $teamId: ID!
    $migId: ID!
    $archived: Boolean!
    $rev: String!
    $tenantId: ID
  ) {
    updateTeamMigArchivedStatus(
      teamId: $teamId
      migId: $migId
      archived: $archived
      rev: $rev
      tenantId: $tenantId
    ) {
      id
      status
      name
    }
  }
`;
