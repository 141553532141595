import { EditOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { MenuProps, Dropdown } from 'antd';
import { Btn } from '../../Button';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../../icons/overviewIcons/CheckIcon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GroupsIcon } from '../../../icons/GroupsIcon';
import { AccountTreeOutlineIcon } from '../../../icons/initiativeIcons/AccountTreeOutlineIcon';
import { ArchiveIcon } from '../../../icons/initiativeIcons/ArchiveIcon';
import { EditInitiativeModal } from './components/editInitiativeModal/EditInitiativeModal';
import { CreateSubInitiativeModal } from './components/createSubInitiativeModal/CreateSubInitiativeModal';
import { MarkInitiativeAsCompletedModal } from './components/markInitiativeAsCompletedModal/MarkInitiativeAsCompletedModal';
import { MarkInitiativeAsArchivedModal } from './components/markInitiativeAsArchivedModal/MarkInitiativeAsArchivedModal';
import { ManageInitiativeTeamsModal } from './components/manageInitiativeTeamsModal/ManageInitiativeTeamsModal';
import { RealignInitiativeModal } from './components/realignInitiativeModal/RealignInitiativeModal';

export const InitiativeActionsDropdown = () => {
  const { t } = useTranslation();
  const { initiativeId } = useParams<{ initiativeId: string }>();

  const [showModal, setShowModal] = useState<
    | 'createSubModal'
    | 'editModal'
    | 'archiveModal'
    | 'markAsCompleted'
    | 'markAsArchived'
    | 'manageTeams'
    | 'realign'
    | null
  >(null);

  const initiativeActions: MenuProps['items'] = [
    {
      key: 'Edit',
      label: t('InitiativeActionsDropdown.edit'),
      icon: <EditOutlined />,
      onClick: () => {
        setShowModal('editModal');
      },
    },
    {
      key: 'Create subinitiative',
      label: t('InitiativeActionsDropdown.createSubinitiative'),
      icon: <PlusOutlined />,
      onClick: () => {
        setShowModal('createSubModal');
      },
    },
    {
      key: 'Manage teams',
      label: <span>{t('InitiativeActionsDropdown.manageTeams')}</span>,
      icon: <GroupsIcon />,
      onClick: () => {
        setShowModal('manageTeams');
      },
    },
    {
      key: 'Realign',
      label: <span>{t('InitiativeActionsDropdown.realign')}</span>,
      icon: <AccountTreeOutlineIcon />,
      onClick: () => {
        setShowModal('realign');
      },
    },
    {
      key: 'Mark as Completed',
      label: <span>{t('InitiativeActionsDropdown.markAsCompleted')}</span>,
      icon: <CheckIcon />,
      onClick: () => {
        setShowModal('markAsCompleted');
      },
    },
    {
      key: 'Archive',
      label: <span>{t('common.archive')}</span>,
      icon: <ArchiveIcon />,
      onClick: () => {
        setShowModal('markAsArchived');
      },
    },
  ];

  return (
    <>
      <Dropdown menu={{ items: initiativeActions }}>
        <Btn icon={<MoreOutlined />} />
      </Dropdown>

      <EditInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'editModal'}
        onClose={() => setShowModal(null)}
      />

      <CreateSubInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'createSubModal'}
        onClose={() => setShowModal(null)}
      />

      <MarkInitiativeAsCompletedModal
        initiativeId={initiativeId}
        visible={showModal === 'markAsCompleted'}
        onClose={() => setShowModal(null)}
      />

      <MarkInitiativeAsArchivedModal
        initiativeId={initiativeId}
        visible={showModal === 'markAsArchived'}
        onClose={() => setShowModal(null)}
      />

      <ManageInitiativeTeamsModal
        initiativeId={initiativeId}
        visible={showModal === 'manageTeams'}
        onClose={() => setShowModal(null)}
      />

      <RealignInitiativeModal
        initiativeId={initiativeId}
        visible={showModal === 'realign'}
        onClose={() => setShowModal(null)}
        onSetAsTopInitiative={() => setShowModal('editModal')}
      />
    </>
  );
};
