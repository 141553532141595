import { Moment } from 'moment';
import {
  AccelerationDay,
  PublishStatus,
} from '../../../../../generated/graphql';
import { isEndOfPeriod } from '../../../../../services/accelerationDayHelpers';
import { momentToDayjs } from '../../../../../services/dateHelpers';
import { getEarliestTermStartDate } from '../../common/services/getEarliestTermStartDate';

// Partly the same as MItemStatus => used for an intital state
export enum DateStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
  OVERDUE = 'OVERDUE',
  PLANNED = 'PLANNED',
  DEFAULT = 'DEFAULT',
}

interface GetSprintDateStatusArgs {
  selectedDate: Moment;
  accelerationDay: AccelerationDay;
  currentSprint?: {
    startDate: Moment | string;
    endDate: Moment | string;
    locked: boolean;
  } | null;
  terms: { startDate: string; endDate: string; status: PublishStatus }[];
}

export const getSprintDateStatus = ({
  selectedDate,
  accelerationDay,
  currentSprint,
  terms,
}: GetSprintDateStatusArgs) => {
  const currentSprintStartDateOrTerm =
    currentSprint != null
      ? currentSprint.startDate
      : getEarliestTermStartDate(terms);
  const noValidDeadline =
    selectedDate.isBefore(currentSprintStartDateOrTerm, 'day') ||
    !isEndOfPeriod(momentToDayjs(selectedDate), accelerationDay);

  const termIntervals =
    terms.filter(
      (t) =>
        t.status === PublishStatus.ACTIVE || t.status === PublishStatus.UPCOMING
    ) ?? [];

  const isDateInTerms = termIntervals.some((term) =>
    selectedDate.isBetween(term.startDate, term.endDate, 'day', '[]')
  );

  const isDefaultStatus = noValidDeadline || !isDateInTerms;

  if (isDefaultStatus) {
    return DateStatus.DEFAULT;
  }

  const activePeriod =
    currentSprint?.locked &&
    selectedDate.isBetween(
      currentSprint?.startDate,
      currentSprint?.endDate,
      'day',
      '[]'
    );

  return activePeriod ? DateStatus.ACTIVE : DateStatus.PLANNED;
};
