import { Modal, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ExpandArrow } from '../../../../../../../components/ExpandArrow';
import { gql } from '@apollo/client';
import { MilestoneStatus } from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '../../../../../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../../../../componentLibrary/Colors';
import { CheckIcon } from '../../../../../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { MilestoneActivitiesTable } from './components/MilestoneActivitiesTable';
import { friendlyDate } from '../../../../../../../services/dateFormats';
import { MilestoneActions } from './components/milestoneActions/MilestoneActions';
import { useMilestoneModal } from './hooks/useMilestoneModal';
import { Suspense } from 'react';
import { EditMilestone } from './components/milestoneActions/components/EditMilestone';
import { Btn } from '../../../../../../../components/Button';
import { MilestoneDetails } from './components/milestoneActions/components/MilestoneDetails';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../../../../components/ErrorPage';
import { StatusTag } from '../../../../../../../components/StatusTag';
import { ManageMilestoneTeams } from './components/milestoneActions/components/ManageMilestoneTeams';

interface Props {
  milestonesWithStats: MilestoneInfoWithStats[];
}

type MilestoneInfoWithStats = {
  milestone: Milestone;
  stats: { completed: number; overdue: number; upcoming: number };
  initiativeDetails: {
    id: string;
    name: string;
    domainId: {
      itemId: string;
      tenantId: string;
    };
  };
};

type Milestone = {
  id: string;
  domainId: {
    itemId: string;
    tenantId: string;
  };
  name: string;
  deadlineAt: string;
  metadata: {
    completedAt: string | null;
    status: MilestoneStatus;
  };
};

export const MilestoneTable = ({ milestonesWithStats }: Props) => {
  const { t } = useTranslation();
  const modalHandler = useMilestoneModal();

  const milestoneColumns: ColumnProps<MilestoneInfoWithStats>[] = [
    {
      title: t('common.deadline'),
      width: 120,
      key: 'deadlineAt',
      dataIndex: ['milestone', 'deadlineAt'],
      render: (deadline) => {
        return <div className="text-c">{friendlyDate(deadline)}</div>;
      },
    },
    {
      title: t('MilestoneTable.statusColumn'),
      key: 'status',
      dataIndex: ['milestone', 'name'],
      render: (text, milestoneWithStats) => {
        return (
          <StatusTag
            status={milestoneWithStats.milestone.metadata.status}
            showIcon
          />
        );
      },
    },
    {
      title: t('MilestoneTable.milestoneColumn'),
      key: 'name',
      dataIndex: ['milestone', 'name'],
      render: (text, milestoneInfoWithStats) => {
        return (
          <div>
            <div>{text}</div>
            <div className="txt--secondary h5">
              {milestoneInfoWithStats.initiativeDetails.name}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
      ),
      key: 'completed',
      width: 90,
      dataIndex: ['stats', 'completed'],
    },
    {
      title: (
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      ),
      key: 'overdue',
      dataIndex: ['stats', 'overdue'],
      width: 90,
    },
    {
      title: (
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
      ),
      key: 'upcoming',
      width: 90,
      dataIndex: ['stats', 'upcoming'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (_, milestoneReport) => {
        return (
          <MilestoneActions
            milestoneName={milestoneReport.milestone.name}
            milestoneId={milestoneReport.milestone.domainId.itemId}
            openModal={modalHandler.openModal}
          />
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const sharedModalProps = {
    footer: null,
    destroyOnClose: true,
    onCancel: modalHandler.closeModal,
  };

  const activeMilestoneId = modalHandler.activeModal?.milestoneId;

  return (
    <div>
      <Modal
        {...sharedModalProps}
        open={modalHandler.activeModal?.type === 'manageTeams'}
      >
        {modalHandler.activeModal?.type === 'manageTeams' &&
          activeMilestoneId && (
            <ManageMilestoneTeams
              milestoneName={modalHandler.activeModal.milestoneName}
              milestoneId={activeMilestoneId}
            />
          )}
      </Modal>
      <Modal
        open={modalHandler.activeModal?.type === 'edit'}
        {...sharedModalProps}
      >
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Suspense fallback={<EditMilestone.Skeleton />}>
            {activeMilestoneId && (
              <EditMilestone milestoneId={activeMilestoneId} />
            )}
          </Suspense>
        </ErrorBoundary>
      </Modal>
      <Modal
        {...sharedModalProps}
        open={modalHandler.activeModal?.type === 'details'}
        title={'Milestone Details'}
        footer={
          activeMilestoneId && (
            <Btn
              type="primary"
              onClick={() => {
                modalHandler.openModal({
                  type: 'edit',
                  milestoneId: activeMilestoneId,
                });
              }}
            >
              Edit Milestone
            </Btn>
          )
        }
      >
        <Suspense fallback={<MilestoneDetails.Skeleton />}>
          {modalHandler.activeModal?.milestoneId && (
            <MilestoneDetails
              milestoneId={modalHandler.activeModal?.milestoneId}
            />
          )}
        </Suspense>
      </Modal>
      <Table
        columns={milestoneColumns}
        dataSource={milestonesWithStats}
        style={{ borderRadius: '6px' }}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (milestoneReport) => (
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <Suspense
                fallback={
                  <MilestoneActivitiesTable.Skeleton
                    numberOfActivities={
                      milestoneReport.stats.completed +
                      milestoneReport.stats.overdue +
                      milestoneReport.stats.upcoming
                    }
                  />
                }
              >
                <MilestoneActivitiesTable
                  milestoneItemId={milestoneReport.milestone.domainId.itemId}
                />
              </Suspense>
            </ErrorBoundary>
          ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandArrow
              onClick={(e) => onExpand(record, e)}
              expanded={expanded}
            />
          ),
        }}
        pagination={false}
        rowKey={(milestoneReport) => milestoneReport.milestone.id}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__MILESTONE_REPORT = gql`
  fragment MilestoneTable__MilestoneReport on MilestoneReport {
    stats {
      completed
      overdue
      upcoming
    }
    milestone {
      id
      deadlineAt
      name
      metadata {
        status
      }
      domainId {
        itemId
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment MilestoneTable__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    milestones {
      ...MilestoneTable__MilestoneReport
    }
    allSubInitiatives {
      milestones {
        ...MilestoneTable__MilestoneReport
      }
    }
  }
`;
