import {
  Gantish__Initiative2Fragment,
  Gantish__InitiativeLightFragment,
  GetTenantBusinessPlanInitiativeDocument,
} from '../../../../generated/graphql';
import { gql, useSuspenseQuery } from '@apollo/client';
import { InitiativeDetailsSkeleton } from './InitiativeDetails.skeleton';
import { ProgressSection } from './progressSection/ProgressSection';
import { TitleSection } from './titleSection/TitleSection';
import { TeamContributionSection } from './teamContributionSection/TeamContributionSection';
import { OverviewSection } from './overviewSection/OverviewSection';
import { InitiativeHeaderSection } from './headerSection/InitiativeHeaderSection';
import {
  InitiativeDetailsNode,
  initiativeDetailsTreeBuilder,
} from '../../../../components/initiatives/Gantish/services/initiativeDetailsTreeBuilder';

interface Props {
  initiativeId: string;
}

export type InitiativeDetailsTreeNode = InitiativeDetailsNode<
  Gantish__Initiative2Fragment,
  Gantish__InitiativeLightFragment
>;

export const InitiativeDetails = ({ initiativeId }: Props) => {
  const { data } = useSuspenseQuery(GetTenantBusinessPlanInitiativeDocument, {
    variables: { initiativeId: initiativeId },
    fetchPolicy: 'cache-and-network',
  });

  const initiativeReportNode = initiativeDetailsTreeBuilder(
    data.initiativeDetailedReport
  );

  return (
    <div>
      <div className="mb--l">
        <TitleSection initiativeReport={data.initiativeDetailedReport} />
      </div>

      <InitiativeHeaderSection
        initiativeReport={data.initiativeDetailedReport}
      />
      <div className="divider mb--l mt--xl" />
      <OverviewSection
        report={data.initiativeDetailedReport}
        initiativeReportNode={initiativeReportNode}
      />
      <div className="divider mb--l mt--xl" />
      <ProgressSection
        report={data.initiativeDetailedReport}
        initiativeReportNode={initiativeReportNode}
      />
      <div className="divider mb--l mt--xl" />
      <TeamContributionSection />
    </div>
  );
};

InitiativeDetails.Skeleton = InitiativeDetailsSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_INITIATIVES = gql`
  query getTenantBusinessPlanInitiative($tenantId: ID, $initiativeId: ID!) {
    initiativeDetailedReport(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      ...TitleSection__InitiativeDetailedReportResponse
      ...InitiativeHeaderSection__InitiativeDetailedReportResponse
      ...InitiativeProgressSection__InitiativeDetailedReportResponse
      ...InitiativeOverviewSection__InitiativeDetailedReportResponse
    }
  }
`;
