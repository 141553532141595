import { useTranslation } from 'react-i18next';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { useState } from 'react';
import { TeamContributionTable } from './TeamContributionTable';

export const TeamContributionSection = () => {
  const { t } = useTranslation();
  const [overviewHeader, setOverviewHeader] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );
  return (
    <div>
      <h3 className="mb">{t('TeamContributionSection.title')}</h3>
      <div className="mb flx flx--jc-space-between flx--ai-center">
        <MilestoneOrActiveToggle
          value={overviewHeader}
          onChange={setOverviewHeader}
        />
      </div>
      <TeamContributionTable />
    </div>
  );
};
