import './TeamGantishLegend.less';
import { TeamGantishMilestoneStats } from './GantishLegend/TeamGantishMilestoneStats';
import { TeamGantishLegendRow } from './GantishLegend/TeamGantishLegendRow';
import { useTranslation } from 'react-i18next';
import { TeamGantishLegendSkeleton } from './TeamGantishLegend.skeleton';
import { gql } from '@apollo/client';
import {
  TeamGantishLegend__InitiativeLightFragment,
  TeamGantishLegend__MilestoneFragment,
} from '../../../../../../generated/graphql';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';

interface Props {
  initiativesWithMilestones: {
    initiative: TeamGantishLegend__InitiativeLightFragment;
    milestones: TeamGantishLegend__MilestoneFragment[];
  }[];
  unassociatedMilestones: TeamGantishLegend__MilestoneFragment[];
}

export const TeamGantishLegend = ({
  initiativesWithMilestones,
  unassociatedMilestones,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="TeamGantishLegend">
      <div className="TeamGantishLegend__row">
        <h5 className="txt--secondary">{t('TeamGantishLegend.title')}</h5>
        <TeamGantishMilestoneStats.Header />
      </div>

      {initiativesWithMilestones?.map((initiativeWithMilestone) => (
        <TeamGantishLegendRow
          milestones={initiativeWithMilestone.milestones}
          label={<InitiativeTag tag={initiativeWithMilestone.initiative.tag} />}
          key={initiativeWithMilestone.initiative.id}
        />
      ))}
      <div className="GantishLegend__row GantishLegend__row--spacer" />

      <TeamGantishLegendRow
        milestones={unassociatedMilestones}
        label={<h4>{t('TeamGantishLegend.otherMilestones')}</h4>}
      />
    </div>
  );
};

TeamGantishLegend.Skeleton = TeamGantishLegendSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GANTISH_LEGEND__INITIATIVE_LIGHT = gql`
  fragment teamGantishLegend__InitiativeLight on InitiativeLight {
    id
    tag {
      iconId
      title
      colorCode
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_GANTISH_LEGEND__MILESTONES = gql`
  fragment teamGantishLegend__Milestone on Milestone {
    id
    ...TeamGantishLegendRow__Milestone
  }
`;
