import {
  Alert,
  Card,
  Collapse,
  Divider,
  Empty,
  Form,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import './MigSetupModalContent.less';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { LineChartOutlined, AlignLeftOutlined } from '@ant-design/icons';
import { HowweError } from '../../../services/error-parser';
import {
  MigRelationSelector,
  SupportMig,
} from '../../../appPages/team/setup/components/MigRelationSelector';
import {
  MigInputV2,
  MigInterval,
  MigPreferredOutcome,
  TeamMigFragment,
} from '../../../generated/graphql';
import {
  friendlyDate,
  standardDateFormat,
} from '../../../services/dateFormats';
import {
  BaseMigPeriod,
  getNumberOfOutcomesMig,
  createEmptyCurve,
} from '../../../services/migUtils';
import { BasicMigSetupForm } from './migSetupModalContent/BasicMigSetupForm';
import { MigCurveEditor } from './migSetupModalContent/MigCurveEditor';
import { WeightedMigSlider } from './migSetupModalContent/basicMigSetupForm/WeightedMigSlider';
import { Btn } from '../../Button';
import dayjs from 'dayjs';
import { without } from '../../../services/without';
import { PreviewMigCard } from './migSetupModalContent/PreviewMigCard';
import { MigDescription } from '../../mig/MigCard/MigDescription';
import { useMyPermissionRoles } from '../../../usePermissions';

interface Props {
  migData?: TeamMigFragment;
  handleSave: (migData: MigInputV2) => void;
  saveErrors?: HowweError[];
  saveLoading?: boolean;
  fetchLoading?: boolean;
  onClose: () => void;
  teamId: string;
  tenantId?: string;
}
const BASIC_INFO_TAB = 'BASIC_INFO';
const CURVE_EDITOR_TAB = 'CURVE_EDITOR';
const MIG_RELATION = 'MIG_RELATION';

export type MigPeriodWithMandatoryGoal = Omit<BaseMigPeriod, 'goal'> & {
  goal: number;
};

export const MigSetupModalContent = ({
  migData,
  handleSave,
  saveErrors,
  saveLoading,
  fetchLoading,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [migWeight, setMigWeight] = useState<number | null>(null);
  const [activeDot, setActiveDot] = useState<number | null>(null);

  useEffect(() => {
    if (migData?.weight) {
      setMigWeight(migData.weight);
    }
  }, [migData]);

  const [activeTab, setActiveTab] = useState(BASIC_INFO_TAB);
  const { isSuperAdmin, isTenantAdmin } = useMyPermissionRoles();
  const isAdmin = isSuperAdmin || isTenantAdmin;

  const [fixedYScale, setFixedYScale] = useState(
    migData?.maxY != null || migData?.minY != null
  );
  const [periodData, setPeriodData] = useState<BaseMigPeriod[]>(() =>
    migData ? migData.periodData.map((pd) => without(pd, '__typename')) : []
  );
  const [periodDataErrors, setPeriodDataErrors] = useState<PeriodDataError[]>(
    []
  );
  const [supportsMigs, setSupportsMigs] = useState<SupportMig[]>(
    () => migData?.supportsMigs ?? []
  );

  const [migMetaEditableData, setMigMetaEditableData] =
    useState<MigEditableData>(
      migData
        ? getEditableFields(migData)
        : {
            timePeriodInterval: MigInterval.MONTH,
            preferredOutcome: MigPreferredOutcome.HIGH,
            name: null,
            description: null,
            startDate: null,
            firstOutcomeDate: null,
            lastOutcomeDate: null,
            unit: null,
            minY: null,
            maxY: null,
            weight: null,
          }
    );

  const canShowCurveEditor =
    migMetaEditableData.firstOutcomeDate && migMetaEditableData.lastOutcomeDate;

  const submit = () => {
    form
      .validateFields()
      .then((values: MigMetaData) => {
        const periodDataErrors = periodData.reduce(
          (acc, p) => {
            if (p.goal == null) {
              return acc.concat({
                message: t('MigSetupModalContent.emptyGoal'),
                field: friendlyDate(p.targetDate),
              });
            }
            return acc;
          },
          [] as { message: string; field: string }[]
        );

        if (periodDataErrors.length > 0) {
          setPeriodDataErrors(periodDataErrors);
          setActiveTab(CURVE_EDITOR_TAB);
        } else {
          const migToSave = {
            ...without(
              fixedYScale ? values : { ...values, minY: null, maxY: null },
              'firstOutcomeDate',
              'lastOutcomeDate'
            ),
            periodData: periodData as MigPeriodWithMandatoryGoal[],
            supportsMigs: supportsMigs.map((sm) => ({
              domainId: without(sm.domainId, '__typename'),
            })),
            weight: migWeight,
          };

          handleSave(migToSave);
        }
      })
      .catch((err) => {
        setActiveTab(BASIC_INFO_TAB);
        form.scrollToField(err.errorFields[0].name);
      });
  };

  const handleMetaDataChange = (newValue: any) => {
    const unitOfTime =
      newValue.timePeriodInterval === MigInterval.WEEK ? 'week' : 'month';

    const lineStartDate = newValue?.firstOutcomeDate?.startOf(unitOfTime);
    const lineLastTargetDate = newValue?.lastOutcomeDate?.endOf(unitOfTime);

    const migFirstOutcomeDate =
      migData?.firstOutcomeDate && dayjs(migData.firstOutcomeDate);
    const migLastOutcomeDate =
      migData?.lastOutcomeDate && dayjs(migData.lastOutcomeDate);

    const numPeriods = getNumberOfOutcomesMig(
      newValue.timePeriodInterval,
      lineStartDate,
      lineLastTargetDate
    );
    const changedFirstDate =
      !migFirstOutcomeDate || !migFirstOutcomeDate.isSame(lineStartDate, 'day');
    const changedLastDate =
      !migLastOutcomeDate ||
      !migLastOutcomeDate.isSame(lineLastTargetDate, 'day');
    const hasChangedInterval =
      migMetaEditableData.timePeriodInterval &&
      migMetaEditableData.timePeriodInterval !== newValue.timePeriodInterval;

    if ((changedFirstDate || hasChangedInterval) && lineStartDate) {
      setPeriodData(
        createEmptyCurve(lineStartDate, numPeriods, newValue.timePeriodInterval)
      );
    }

    if (changedLastDate && lineStartDate) {
      const baseCurve = createEmptyCurve(
        lineStartDate,
        numPeriods,
        newValue.timePeriodInterval
      );

      const newCurveWithOldPeriods = baseCurve.map((bcp) => {
        const periodDataPeriod = periodData.find(
          (p) => p.targetDate === bcp.targetDate
        );
        return periodDataPeriod || bcp;
      });

      setPeriodData(newCurveWithOldPeriods);
    }

    setMigMetaEditableData({
      ...newValue,
      firstOutcomeDate: lineStartDate,
      lastOutcomeDate: lineLastTargetDate,
    });
  };

  const endDateHasPassed = migData?.lastOutcomeDate
    ? dayjs(migData.lastOutcomeDate).isSameOrBefore(dayjs(), 'day')
    : false;

  return (
    <div className="MigSetupModalContent">
      <div className="flx flx--column MigSetupModalContent__inputArea">
        <div className="pa--l">
          <Tabs activeKey={activeTab} onTabClick={setActiveTab}>
            <Tabs.TabPane
              key={BASIC_INFO_TAB}
              tab={
                <span data-intercom-target="MIG Setup Basic Info Tab">
                  {t('MigSetupModalContent.basicInfoTab')}
                </span>
              }
            ></Tabs.TabPane>
            <Tabs.TabPane
              key={CURVE_EDITOR_TAB}
              tab={
                <span data-intercom-target="MIG Setup Goal Adjustment Tab">
                  {t('MigSetupModalContent.goalLineTab')}
                </span>
              }
            ></Tabs.TabPane>
            <Tabs.TabPane
              key={MIG_RELATION}
              tab={
                <span data-intercom-target="MIG Setup Relation Tab">
                  {t('MigSetupModalContent.migRelation')}
                </span>
              }
            ></Tabs.TabPane>
          </Tabs>
        </div>

        <div className="pa--l MigSetupModalContent__scroll">
          <Spin spinning={fetchLoading}>
            <div>
              {periodDataErrors.length > 0 && (
                <Alert
                  className="mb"
                  message={t('MigSetupModalContent.saveError')}
                  description={
                    <ul>
                      {periodDataErrors.map((e) => (
                        <li key={e.field + '-' + e.message}>
                          {e.field + ' ' + e.message}
                        </li>
                      ))}
                    </ul>
                  }
                  type="error"
                />
              )}

              <ErrorBoundary>
                <>
                  <BasicMigSetupForm
                    formRef={form}
                    migData={migMetaEditableData}
                    onChange={handleMetaDataChange}
                    isEdit={!!migData}
                    endDateHasPassed={endDateHasPassed}
                    fixedYScale={fixedYScale}
                    setFixedYScale={setFixedYScale}
                    visible={activeTab === BASIC_INFO_TAB}
                  />
                  {isAdmin && activeTab === BASIC_INFO_TAB && (
                    <>
                      <Divider />
                      <Collapse accordion>
                        <Collapse.Panel
                          header={t('MigSetupModalContent.adminHeader')}
                          key="AdminControls"
                        >
                          <WeightedMigSlider
                            onChange={(value) => setMigWeight(value)}
                            value={migWeight}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    </>
                  )}
                </>
              </ErrorBoundary>

              {activeTab === CURVE_EDITOR_TAB &&
                migMetaEditableData.firstOutcomeDate &&
                migMetaEditableData.lastOutcomeDate && (
                  <MigCurveEditor
                    data={{
                      ...migMetaEditableData,
                      firstOutcomeDate: migMetaEditableData.firstOutcomeDate,
                      lastOutcomeDate: migMetaEditableData.lastOutcomeDate,
                    }}
                    setActiveDot={setActiveDot}
                    periodData={periodData}
                    setPeriodData={setPeriodData}
                  />
                )}
              {activeTab === CURVE_EDITOR_TAB && !canShowCurveEditor && (
                <Empty
                  description={t('MigSetupModalContent.datesRequired')}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
              {activeTab === MIG_RELATION && (
                <MigRelationSelector
                  teamId={teamId}
                  tenantId={tenantId}
                  currentMigId={migData?.domainId.itemId}
                  value={supportsMigs}
                  onChange={setSupportsMigs}
                />
              )}
            </div>
            {saveErrors && (
              <Alert
                className="mt"
                message={t('MigSetupModalContent.saveError')}
                description={
                  <ul>
                    {saveErrors.map((e, i) => (
                      <li key={i}>{e.translation}</li>
                    ))}
                  </ul>
                }
                type="error"
              />
            )}
          </Spin>
        </div>

        <div className="flx flx--jc-flx-end pa">
          <Btn type="primary" onClick={submit} loading={saveLoading}>
            {t('MigSetupModalContent.saveButton')}
          </Btn>
        </div>
      </div>
      <div className="MigSetupModalContent__previewArea MigSetupModalContent__scroll">
        <Spin spinning={fetchLoading} className="center-content">
          <div className="pa--xl">
            <Typography.Title level={3}>
              {t('MigSetupModalContent.preview')}
            </Typography.Title>
            <Typography.Text strong className="mt">
              <div>
                <LineChartOutlined className="mr" />
                {t('MigSetupModalContent.frontOfCard')}
              </div>
            </Typography.Text>
            <Card bodyStyle={{ padding: 0 }} className="mb--l mt--s">
              <PreviewMigCard
                mig={{
                  ...migMetaEditableData,
                  lastOutcomeDate:
                    migMetaEditableData.lastOutcomeDate &&
                    standardDateFormat(migMetaEditableData.lastOutcomeDate),
                  periodData,
                  mostRecentReport: migData?.mostRecentReport,
                }}
                showDescription={false}
                activeDot={activeDot}
                graphHeight={120}
              />
            </Card>
            <Typography.Text strong>
              <div>
                <AlignLeftOutlined className="mr" />
                {t('common.description')}
              </div>
            </Typography.Text>
            <Card bodyStyle={{ padding: 0 }} className="mt--s">
              <MigDescription /* @FredColl tried using just MigDescription component here but there still some problems  */
                mig={{
                  id: '',
                  description: migMetaEditableData.description ?? '',
                  weight: migWeight,
                  supportsMigs,
                  ...migMetaEditableData,
                  supportedByMigs: [],
                }}
              />
            </Card>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export interface MigEditableData {
  name: string | null;
  description?: string | null;
  startDate: string | null;
  firstOutcomeDate: string | null;
  lastOutcomeDate: string | null;
  timePeriodInterval: MigInterval;
  preferredOutcome: MigPreferredOutcome;
  unit: string | null;
  minY?: number | null;
  maxY?: number | null;
  weight?: number | null;
}

export interface MigMetaData {
  name: string;
  description?: string;
  startDate: string;
  firstOutcomeDate: string;
  lastOutcomeDate: string;
  timePeriodInterval: MigInterval;
  preferredOutcome: MigPreferredOutcome;
  unit: string;
  minY?: number;
  maxY?: number;
  weight?: number;
}

interface PeriodDataError {
  message: string;
  field: string;
}

const getEditableFields = ({
  name,
  description,
  startDate,
  firstOutcomeDate,
  lastOutcomeDate,
  timePeriodInterval,
  preferredOutcome,
  unit,
  minY,
  maxY,
  weight,
}: TeamMigFragment): MigEditableData => ({
  name,
  description,
  startDate,
  timePeriodInterval,
  firstOutcomeDate,
  lastOutcomeDate,
  preferredOutcome,
  unit,
  minY,
  maxY,
  weight,
});
