import dayjs from 'dayjs';
import './TeamGantishChartRow.less';
import { TeamGantishMonthCell } from './TeamGantishMonthCell';

export const TeamGantishChartRowSkeleton = ({
  months,
}: {
  months: dayjs.Dayjs[];
}) => {
  return (
    <div>
      <div className="TeamGantishChartRow gantishShared-AppendAnimation ">
        {months.map((m, i) => (
          <TeamGantishMonthCell key={i} startOfMonth={m}></TeamGantishMonthCell>
        ))}
      </div>
    </div>
  );
};
