import { Form } from 'antd';
import { MilestoneForm } from '../../../../createMilestoneButton/components/MilestoneForm';
import { Btn } from '../../../../../../../../../../components/Button';
import { useTranslation } from 'react-i18next';

export const EditMilestoneSkeleton = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <div>
      <MilestoneForm form={form} loading />
      <div className="flx flx--jc-flx-end mt--xl">
        <Btn>{t('common.save')} (not implemented)</Btn>
      </div>
    </div>
  );
};
