import { useSuspenseQuery, useMutation, gql } from '@apollo/client';
import { FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  GetTenantInitiativeForCreateSubinitiativeDocument,
  CreateSubInitiativeDocument,
  SupportedEntityType,
} from '../../../../../generated/graphql';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { Btn } from '../../../../Button';
import { InitiativeForm } from '../../../InitiativeForm/InitiativeForm';
import { utcTimeFormat } from '../../../../../services/dateFormats';
import dayjs from 'dayjs';

interface Props {
  form: FormInstance;
  initiativeId: string;
  onSuccess: () => void;
}

export const CreateSubinitiativeModalContent = ({
  form,
  initiativeId,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data } = useSuspenseQuery(
    GetTenantInitiativeForCreateSubinitiativeDocument,
    { variables: { initiativeId: initiativeId }, fetchPolicy: 'no-cache' }
  );

  const [createSubInitiative, { loading }] = useMutation(
    CreateSubInitiativeDocument
  );
  const initiative = data.tenantInitiative;

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { description, name, assignedTo, tagTitle, startAt, endAt } =
        values;
      const objectives: string[] = values.objectives ?? [];

      createSubInitiative({
        variables: {
          initiative: {
            description,
            assignedTo: [
              {
                id: assignedTo,
              },
            ],
            objectives: objectives
              .filter((o) => !!o)
              .map((objective: string) => ({
                text: objective,
              })),
            supports: [
              {
                type: SupportedEntityType.initiative,
                item: {
                  domainId: {
                    itemId: initiative.domainId.itemId,
                    tenantId: initiative.domainId.tenantId,
                  },
                },
              },
            ],
            name: name,
            startAt: utcTimeFormat(startAt ?? dayjs()),
            endAt: endAt && utcTimeFormat(endAt),
            tag: {
              iconId: initiative.tag.iconId,
              colorCode: initiative.tag.colorCode,
              title: tagTitle,
            },
          },
        },

        onCompleted: (data) => {
          showNotification('success', {
            message: (
              <strong>
                {t('common.initiative.createSuccess', {
                  name: data.createTenantInitiative.name,
                })}
              </strong>
            ),
          });
          onSuccess();
          history.push(
            '/company/initiatives/' +
              data.createTenantInitiative.domainId.itemId +
              ``
          );
        },
        onError: (e) => {
          const howweErrors = howweErrorParser(e);

          showNotification('error', {
            message: (
              <strong>
                <ul>
                  {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
                </ul>
              </strong>
            ),
          });
        },
      });
    });
  };

  return (
    <>
      <InitiativeForm
        form={form}
        parentInitiativeTag={data.tenantInitiative.tag}
      />
      <Btn loading={loading} onClick={handleSubmit}>
        Submit
      </Btn>
    </>
  );
};

CreateSubinitiativeModalContent.Skeleton = InitiativeForm.Skeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_EDIT_INITIATIVE_CONTENT = gql`
  query getTenantInitiativeForCreateSubinitiative(
    $tenantId: ID
    $initiativeId: ID!
  ) {
    tenantInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      ...InitiativeForm_Initiative2
      ...CreateSubinitiativeModalContent__Initiative2
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_SUB_INITIATIVE__TAG = gql`
  fragment CreateSubinitiativeModalContent__Initiative2 on Initiative2 {
    id
    domainId {
      itemId
      tenantId
    }
    name
    tag {
      title
      iconId
      colorCode
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_SUB_INITIATIVE = gql`
  mutation createSubInitiative($tenantId: ID, $initiative: Initiative2Input!) {
    createTenantInitiative(tenantId: $tenantId, initiative: $initiative) {
      id
      name
      description
      domainId {
        itemId
      }
    }
  }
`;
