import { gql } from '@apollo/client';
import { TitleSection__InitiativeDetailedReportResponseFragment } from '../../../../../generated/graphql';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { Icons } from '../../../../../components/initiatives/InitiativeIcons';
import { TitleSectionSkeleton } from './TitleSection.Skeleton';

type Props = {
  initiativeReport: TitleSection__InitiativeDetailedReportResponseFragment;
};

export const TitleSection = ({ initiativeReport }: Props) => {
  const { initiative } = initiativeReport || {};

  return (
    <div className="flx--1">
      <div className="flx flx--ai-center">
        <h1>{initiative.name}</h1>
        {initiative.tag && (
          <InitiativeTag
            title={initiative.tag.title}
            className="ml"
            borderColor={initiative.tag.colorCode}
            icon={Icons[initiative.tag.iconId as keyof typeof Icons]}
            completed={!!initiative?.metadata.completedAt}
            archived={initiative?.metadata.archived}
          />
        )}
      </div>
    </div>
  );
};

TitleSection.Skeleton = TitleSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TITLE_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment TitleSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    initiative {
      id
      domainId {
        itemId
        tenantId
      }
      name
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        completedAt
        archived
      }
    }
  }
`;
