import { CheckIcon } from '../../../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../icons/overviewIcons/UpcomingEventIcon';
import { WarningIcon } from '../../../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../../../appPages/componentLibrary/Colors';
import './MilestoneStats.less';
import Skeleton from 'react-loading-skeleton';

export const MilestoneStatsSkeleton = () => {
  return (
    <div className="MilestoneStats">
      <div className="MilestoneStats__stat">{<Skeleton />}</div>
      <div className="MilestoneStats__stat">{<Skeleton />}</div>
      <div className="MilestoneStats__stat">{<Skeleton />}</div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="MilestoneStats">
      <div className="MilestoneStats__stat">
        <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
      </div>
      <div className="MilestoneStats__stat">
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      </div>
      <div className="MilestoneStats__stat">
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
      </div>
    </div>
  );
};

MilestoneStatsSkeleton.Header = Header;
