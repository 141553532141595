import './LegendSubInitiative.less';
import { MilestoneStats } from './MilestoneStats';
import Skeleton from 'react-loading-skeleton';

export const LegendSubInitiativeSkeleton = () => {
  return (
    <div className="LegendSubinitiative gantishShared-AppendAnimation">
      <div className="LegendSubinitiative__row">
        <Skeleton style={{ width: '150px', fontSize: 11 }} />

        <MilestoneStats.Skeleton />
      </div>
    </div>
  );
};
