import { useTranslation } from 'react-i18next';
import { ManageInitiativeTeamsModalContentSkeleton } from './ManageInitiativeTeamsModalContent.skeleton';
import {
  GetTeamsLinkedToInitiativeDocument,
  ManageInitiativeTeams__TeamFragment,
  TeamLinkTypes,
} from '../../../../../generated/graphql';

import Table, { ColumnProps } from 'antd/es/table';
import { EnrollTeamToInitiative } from './EnrollTeamToInitiative';
import { RemoveTeamLink } from './RemoveTeamLink';
import { gql, useSuspenseQuery } from '@apollo/client';

interface Props {
  initiativeId: string;
}

export const ManageInitiativeTeamsModalContent = ({ initiativeId }: Props) => {
  const { t } = useTranslation();

  const { data } = useSuspenseQuery(GetTeamsLinkedToInitiativeDocument, {
    variables: { initiativeId },
  });

  const columns: ColumnProps<ManageInitiativeTeams__TeamFragment>[] = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      key: 'name',
    },
    {
      defaultSortOrder: 'ascend',
      key: 'actions',
      width: 150,
      render: (_, record) => {
        return (
          <RemoveTeamLink
            domainId={{ itemId: initiativeId, tenantId: record.tenantId }}
            teamId={record.id}
            linkType={TeamLinkTypes.INITIATIVE}
            mutationOptions={{
              refetchQueries: [
                {
                  query: GetTeamsLinkedToInitiativeDocument,
                  variables: { initiativeId },
                },
              ],
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="flx flx--column">
      <h2 className="mb--xl">{t('ManageInitiativeTeamsModalContent.title')}</h2>
      <div className="mb--xl">
        <h3 className="mb">
          {t('ManageInitiativeTeamsModalContent.assignTeam')}
        </h3>
        <EnrollTeamToInitiative
          initiativeId={initiativeId}
          linkedTeams={data.teamsLinkedToInitiative.linkedTeams}
        />
      </div>
      <h3 className="mb">
        {t('ManageInitiativeTeamsModalContent.alreadyAssignedTeams')}
      </h3>

      <Table
        size="middle"
        className="showScrollBar"
        rowKey={(record) => record.name}
        dataSource={data.teamsLinkedToInitiative.linkedTeams.map(
          (team) => team.team
        )}
        columns={columns}
        loading={false}
        pagination={false}
      />
    </div>
  );
};

ManageInitiativeTeamsModalContent.Skeleton =
  ManageInitiativeTeamsModalContentSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MANAGE_INITIATIVE_TEAMS_GET_LINKED_TEAMS = gql`
  query getTeamsLinkedToInitiative($tenantId: ID, $initiativeId: ID!) {
    teamsLinkedToInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      linkedTeams {
        ...EnrollTeamToInitiative__TeamId
        team {
          ...manageInitiativeTeams__team
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MANAGE_INITIATIVE_TEAMS__TEAM = gql`
  fragment manageInitiativeTeams__team on Team {
    id
    tenantId
    name
  }
`;
