import dayjs from 'dayjs';
import './ChartSubinitiative.less';
import { MonthCell } from './MonthCell';

export const ChartSubinitiativeSkeleton = ({
  months,
}: {
  months: dayjs.Dayjs[];
}) => {
  return (
    <div>
      <div className="ChartSubinitiative gantishShared-AppendAnimation ">
        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m}></MonthCell>
        ))}
      </div>
    </div>
  );
};
