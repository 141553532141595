import dayjs from 'dayjs';
import { useState } from 'react';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';

import { MonthSlices } from './GantishChart/MonthSlices';
import { Btn } from '../../../Button';
import './GantishChart.less';
import { ChartSubinitiative } from './GantishChart/ChartSubinitiative';
import { MonthCell } from './GantishChart/MonthCell';
import { GantishChartSkeleton } from './GantishChart.skeleton';
import { gql } from '@apollo/client';
import { InitiativeDetailsTreeNode } from '../../../../appPages/company/initiatives/initiativeDetails/InitiativeDetails';

interface Props {
  initiativeNode: InitiativeDetailsTreeNode;
  expanded: string[];
}

const today = dayjs();

export const GantishChart = (props: Props) => {
  const [startDate, setStartDate] = useState(dayjs('2024-01-01'));

  const months = Array.from({ length: 6 }, (_, i) => startDate.add(i, 'month'));

  const window = {
    start: months[0],
    end: months[months.length - 1].endOf('month'),
  };

  const { children } = props.initiativeNode;

  const handleBack = () => {
    setStartDate((prev) => prev.subtract(WINDOW_STEP_SIZE, 'month'));
  };

  const handleForward = () => {
    setStartDate((prev) => prev.add(WINDOW_STEP_SIZE, 'month'));
  };

  const monthProgress = (today.date() / today.daysInMonth()) * 100;

  return (
    <div
      className="GantishChart"
      style={{ ['--month-percent' as any]: monthProgress + '%' }}
    >
      <div className="GantishChart__row GantishChart__row--header">
        <Btn
          className="GantishChart__backBtn"
          icon={<CaretLeftFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          onClick={handleBack}
        />

        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m}>
            <span className="ml">
              {m.format(isJanuary(m) ? 'MMM YYYY' : 'MMM')}
            </span>
          </MonthCell>
        ))}

        <Btn
          className="GantishChart__forwardBtn"
          icon={<CaretRightFilled style={{ fontSize: ICON_SIZE }} />}
          type="link"
          onClick={handleForward}
        />
      </div>

      <div className="GantishChart__row">
        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m}>
            <MonthSlices
              startOfMonth={m}
              initiativeNode={props.initiativeNode}
              window={window}
            />
          </MonthCell>
        ))}
      </div>

      <div className="GantishChart__row GantishChart__row--spacer">
        {months.map((m, i) => (
          <MonthCell key={i} startOfMonth={m} />
        ))}
      </div>

      <div id="subInitiatives">
        {children?.map((subInitiative) => (
          <ChartSubinitiative
            initiative={subInitiative}
            months={months}
            window={window}
            key={subInitiative.id}
            expanded={props.expanded}
          />
        ))}
      </div>
    </div>
  );
};

const isJanuary = (date: dayjs.Dayjs) => date.month() === 0;

const ICON_SIZE = 11;
const WINDOW_STEP_SIZE = 1;

GantishChart.Skeleton = GantishChartSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GANTISH_CHART__INITIATIVE2 = gql`
  fragment gantishChart__Initiative2 on Initiative2 {
    id
    startAt
    endAt
  }
`;
