import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import { stringSort } from '../../../../../../services/stringSort';

interface Props {
  initiatives?: {
    id: string;
    tag: { iconId: string; title: string; colorCode: string };
    completed: { value: boolean } | null;
    archived: boolean;
  }[];
  selectedInitiativeIds?: string[];
  value?: string;
  onChange?: (migId: string) => void;
  className?: string;
  disabled?: boolean;
  placeHolder?: string;
  loadingInitiatives?: boolean;
}

export const InitiativeSelector = ({
  initiatives,
  selectedInitiativeIds,
  loadingInitiatives,
  onChange,
  className,
  disabled,
  value,
  placeHolder,
}: Props) => {
  const { t } = useTranslation();

  const options = initiatives
    ?.toSorted((a, b) => {
      return stringSort(a.tag.title, b.tag.title);
    })
    .map((t) => ({
      value: t.id,
      disabled: selectedInitiativeIds?.includes(t.id),
      label: (
        <div>
          <InitiativeTag
            tag={t.tag}
            completed={t.completed?.value}
            archived={t.archived}
          ></InitiativeTag>
        </div>
      ),
      searchText: t.tag.title,
    }));

  return (
    <Select
      loading={loadingInitiatives}
      showSearch
      placeholder={placeHolder ?? t('InitiativeSelector.placeholder')}
      optionFilterProp="children"
      disabled={disabled}
      onChange={onChange}
      value={value}
      className={className}
      options={options}
      filterOption={(input, option) =>
        option?.searchText?.toLowerCase().includes(input.toLowerCase()) ?? false
      }
    />
  );
};
