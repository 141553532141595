import { gql } from '@apollo/client';
import { InitiativesTree } from './initaitiveTree/InitiativesTree';
import { useRouteMatch } from 'react-router-dom';
import { navigationTreeBuilder } from './navigationTreeBuilder';
import { useMemo } from 'react';
import { InitiativeTreeWrapper__TopInitiativeReportFragment } from '../../../../../generated/graphql';
interface Props {
  initiatives: InitiativeTreeWrapper__TopInitiativeReportFragment[];
}

export const InitiativeTreeWrapper = ({ initiatives }: Props) => {
  const match = useRouteMatch<{ initiativeId: string }>(
    '/company/initiatives/:initiativeId'
  );

  const initiativeId = match?.params.initiativeId ?? undefined;
  const memoizedNavigationTree = useMemo(
    () => navigationTreeBuilder(initiatives),
    [initiatives]
  );

  return memoizedNavigationTree.length > 0 ? (
    <InitiativesTree
      selectedInitiativeId={initiativeId}
      treeData={memoizedNavigationTree}
    />
  ) : (
    <div className="txt--secondary">-</div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TREE_WRAPPER_TOP_INITIATIVE_REPORT = gql`
  fragment initiativeTreeWrapper__TopInitiativeReport on TopInitiativeReport {
    id
    initiative {
      ...initiativeTreeWrapper__Initiative2
    }
    allSubInitiatives {
      ...initiativeTreeWrapper__InitiativeLight
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TREE_WRAPPER__INITIATIVE = gql`
  fragment initiativeTreeWrapper__Initiative2 on Initiative2 {
    domainId {
      itemId
      tenantId
    }
    startAt
    endAt
    name
    id
    tag {
      ...initiativeTreeWrapper__InitiativeTag
    }
    metadata {
      completedAt
      archived
      status
      supports {
        ... on SupportedInitiative {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ... on SupportedMig {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TREE_WRAPPER__INITIATIVE_LIGHT = gql`
  fragment initiativeTreeWrapper__InitiativeLight on InitiativeLight {
    id
    domainId {
      itemId
      tenantId
    }
    tag {
      ...initiativeTreeWrapper__InitiativeTag
    }
    name
    metadata {
      archived
      completedAt
      status
    }
    startAt
    endAt
    metadata {
      status
      supports {
        ... on SupportedInitiativeLight {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ... on SupportedMigLight {
          type
          item {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TREE_WRAPPER__INITIATIVE_TAG = gql`
  fragment initiativeTreeWrapper__InitiativeTag on InitiativeTag {
    title
    iconId
    colorCode
  }
`;
