export const toMap = <T extends {}>(
  arr: T[],
  keySelector: (item: T) => string
) => {
  const map: {
    [key: string]: T;
  } = {};
  for (const item of arr) {
    const key = keySelector(item);
    map[key] = item;
  }
  return map;
};

export const toMapV2 = <T extends {}, U>(
  arr: T[],
  keySelector: (item: T) => string,
  valueSelector: (item: T) => U
) => {
  const map: {
    [key: string]: U;
  } = {};

  for (const item of arr) {
    const key = keySelector(item);
    const value = valueSelector(item);
    map[key] = value;
  }

  return map;
};
